import React from "react";
import "./Calendar.css";
import CalendarSidebar from "./CalendarSidebar/CalendarSidebar";
import "react-calendar/dist/Calendar.css";
import "./customCalendar.css";
import CustomCalendar from "./CustomCalendar";

const CalendarPage = () => {
  return (
    <div className="calendar_container">
      <CalendarSidebar />
      <div className="calendar-custom">
        <CustomCalendar />
      </div>
    </div>
  );
};

export default CalendarPage;
