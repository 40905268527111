import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MedicineMain from "./MedicineContainer/MedicineMain/MedicineMain";
import MedicineSidebar from "./MedicineContainer/MedicineSidebar/MedicineSidebar";
import { useApp } from "../../AppProvider";
import { useAuth } from "../../AuthProvider";
import "./MedicineContainer/MedicineMain/MedicineMain.css";

const MedicineGroups = () => {
  const navigate = useNavigate();
  const { MediRecordId } = useParams();
  const { accessToken, user } = useAuth();
  const [groupName, setGroupName] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentMediRecordId, setCurrentMediRecordId] = useState(MediRecordId);
  const { medicationGroup, setMedicationGroup } = useApp();


  useEffect(() => {
    if (MediRecordId === undefined) {
      const firstMediRecordId = medicationGroup[0]?._id;
      if (firstMediRecordId) {
        setCurrentMediRecordId(firstMediRecordId);
        navigate(`/setting/managemedicinegroups/${firstMediRecordId}`);
      }
    }
  }, [MediRecordId, medicationGroup, navigate]);

  const handleGroupNameChange = (newGroupName) => {
    setGroupName(newGroupName);
  };

  const deleteMedicineGroupData = async (id) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/medicationGroups/${id}`;
    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }

      console.log("Deleted medicine data:", id);
    } catch (error) {
      console.error("Error deleting medicine data:", error.message);
    }
  };
  const removeMedicationGroupFromDoctor = async (id) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/doctors/medicationgroups/${user._id}`;
    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
        body: JSON.stringify({ medicationGroups: `${id}` }),
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Updated medicine group to Doctor:", data);
      return data

    } catch (error) {
      console.error("Error updating medicine data to Doctor:", error.message);
    }
  };

  const handleDeleteCard = async () => {
    if (selectedCard) {
      try {
        await removeMedicationGroupFromDoctor(currentMediRecordId)
        try {
          await deleteMedicineGroupData(currentMediRecordId)
          const updatedData = medicationGroup.filter(
            (card) => card._id !== currentMediRecordId
          );
          setMedicationGroup(updatedData);
          setSelectedCard(updatedData[0]);
          setCurrentMediRecordId(updatedData[0]._id);
          navigate(`/setting/managemedicinegroups/${updatedData[0]._id}`);
        } catch (error) {
          console.error("Error in Deleting Group");
        }

      } catch (e) {
        console.error("Error in Deleting Group");
      } 
    }
  };

  useEffect(() => {
    setCurrentMediRecordId(MediRecordId);
  }, [MediRecordId]);

  useEffect(() => {
    const card = medicationGroup?.find(
      (card) => card._id === currentMediRecordId
    );
    setSelectedCard(card);
  }, [currentMediRecordId]);

  return (
    <>
      <MedicineSidebar
        groupName={groupName}
        medicineData={medicationGroup}
        selectedCard={selectedCard}
        MediRecordId={currentMediRecordId}
        setMedicineData={setMedicationGroup}
      />
      <MedicineMain
        handleGroupNameChange={handleGroupNameChange}
        medicineData={medicationGroup}
        handleDeleteCard={handleDeleteCard}
        selectedCard={selectedCard}
        MediRecordId={currentMediRecordId}
        setSelectedCard={setSelectedCard}
      />
    </>
  );
};

export default MedicineGroups;
