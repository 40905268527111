import React from "react";
import "./Dashboard.css";
import { useApp } from "./../../../AppProvider";

const Dashboard = () => {
  const { selectedPatientObj, patientsMedicine } = useApp();

  const calculateAge = (dateOfBirth) => {
    const dobDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < dobDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  function availableCondition(condition) {
    return condition !== null && condition !== undefined ? condition : "NA";
  }

  const getFullName = (firstName, lastName) => {
    if (!firstName && !lastName) {
      return "Name Unavailable";
    }
    return `${firstName || ""} ${lastName || ""}`.trim();
  };

  return (
    <div className="dashboadr_container">
      <div className="left_section">
        <div className="top">
          <img
            src="https://th.bing.com/th/id/OIP.SfjQckMWJ4zUe2juXkYw6AAAAA?w=238&h=238&rs=1&pid=ImgDetMain"
            alt=""
          />
          <h3>
            {/* {(selectedPatientObj.name.firstName) + " " + (selectedPatientObj.name.lastName) || ""} */}
            {getFullName(
              selectedPatientObj?.name?.firstName,
              selectedPatientObj?.name?.lastName
            )}
          </h3>
        </div>
        <div className="bottom">
          {/* GENERAL INFO */}
          <div className="general-info">
            <span className="title-tag">General Info</span>
            <table className="info">
              <tr>
                <td className="filled">Gender</td>
                <td className="input">
                  {/* {availableCondition(selectedPatientObj.gender)} */}
                  {selectedPatientObj ? availableCondition(selectedPatientObj.gender) : "NA"}
                </td>
              </tr>
              <tr>
                <td className="filled">Age</td>
                <td className="input">
                  {/* {calculateAge(selectedPatientObj.dateOfBirth)} */}
                  {selectedPatientObj ? calculateAge(selectedPatientObj.dateOfBirth) : "NA"}
                </td>
              </tr>
              <tr>
                <td className="filled">Weight</td>
                <td className="input">
                  {/* {availableCondition(selectedPatientObj.weight)} */}
                  {selectedPatientObj ? availableCondition(selectedPatientObj.weight) : "NA"}
                </td>
              </tr>
              <tr>
                <td className="filled">Blood Group</td>
                <td className="input">
                  {/* {availableCondition(selectedPatientObj.bloodGroup)} */}
                  {selectedPatientObj ? availableCondition(selectedPatientObj.bloodGroup) : "NA"}
                </td>
              </tr>
            </table>
          </div>

          {/* MEDICAL CONDITIONS */}
          <div className="general-info">
            <span className="title-tag">Medical Conditions</span>
            <div className="medical-condition_container">
              {selectedPatientObj &&
                selectedPatientObj.chronicConditions &&
                selectedPatientObj.chronicConditions.length > 0 ? (
                selectedPatientObj.chronicConditions.map((condition, index) => (
                  <div className="medical-input">{condition}</div>
                ))
              ) : (
                <div className="sidebar-data-not-available-medical">
                  Data Not Available
                </div>
              )}
            </div>
          </div>
          {/* ALLERGIES */}
          <div className="general-info">
            <span className="title-tag">Allergies</span>
            <div className="allergies-container">
              {selectedPatientObj && selectedPatientObj.allergies ? (
                selectedPatientObj.allergies.map((value, index) => (
                  <div className="allergies-input" key={index}>
                    {value}
                  </div>
                ))
              ) : (
                <div className="sidebar-data-not-available">
                  Data Not Available
                </div>
              )}
            </div>
          </div>
          {/* ADDICTIVE HABBITS */}
          <div className="general-info">
            <span className="title-tag">Addictive Habbits</span>
            {
              selectedPatientObj && selectedPatientObj.addictionInformation ? (
                selectedPatientObj.addictionInformation.length > 0 ? (
                  selectedPatientObj.addictionInformation.map((value, index) => (
                    <div className="addictive-habbit" key={index}>
                      {value}
                    </div>
                  ))
                ) : (
                  <div className="sidebar-data-not-available">
                    Data Not Available
                  </div>
                )
              ) : (
                <div className="sidebar-data-not-available">
                  Data Not Available
                </div>
              )
            }
          </div>
          <div className="general-info">
            <span className="title-tag">Ongoing Medication</span>
            <div className="ongingmedication-container">
              {patientsMedicine && patientsMedicine.length > 0 ? (
                patientsMedicine.map((medication, index) => (
                  <div className="ongoing-input" key={index}>
                    {availableCondition(medication.drug)}
                  </div>
                ))
              ) : (
                <div className="sidebar-data-not-available-onging">
                  Data Not Available
                </div>
              )}
            </div>
          </div>
          <div className="general-info">
            <span className="title-tag">Heredity</span>
            <div className="heredity-input-div">
              {selectedPatientObj &&
                selectedPatientObj.heridityTags.length > 0 ? (
                selectedPatientObj.heridityTags.map((tag, index) => (
                  <div className="heredity-input" key={index}>
                    {tag}
                  </div>
                ))
              ) : (
                <div className="sidebar-data-not-available-heredity">
                  Data Not Available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
