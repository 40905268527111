import React, { useEffect, useState } from "react";
import "./Login.css";
import { useAuth } from "./../../AuthProvider";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LogoWithName from "./image/Logo-with-Name.svg";
import Loader from "../../Components/GlobalLoader/Loader";
import DOMPurify from 'dompurify';

const Login = () => {
  const navigate = useNavigate(); 
  const location = useLocation(); 
  const { login, isLoggingIn, user } = useAuth(); 
  const from = location.state?.from?.pathname || "/";   
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);  
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 7000); 

    window.addEventListener("beforeunload", () => {
      setLoading(true); 
    });

    return () => clearTimeout(timer);
  }, []);

  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  const onSubmit = async () => {
    setUsernameError(null);
    setPasswordError(null);
  
    const sanitizedUsername = DOMPurify.sanitize(username);
    const sanitizedPassword = DOMPurify.sanitize(password);
  
    if (!sanitizedUsername) {
      setUsernameError("Please enter your username");
      return;
    }
    if (!sanitizedPassword) {
      setPasswordError("Please enter your password");
      return;
    }
  
    try {
      setLoading(true); 
      await login(sanitizedUsername, sanitizedPassword);
      navigate("/");
    } catch (error) {
      alert("Invalid username or password. Please try again.");
    } finally {
      setLoading(false); 
    }
  };
  

  return (
    <div className="container">
      <div className="login_container">
        <div className="left_container-section">
          <h1></h1>
          <img src={LogoWithName} alt="logo" />
        </div>
        <div className="right_container-section">
          <h1 className="hello-agin">Doctor's Login</h1>
          <p className="happy-text"></p>
          <div className="input-section">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="forgot-password">
            <div className="remember-checkbox">
              <input type="checkbox" />
              <span>Remember Me</span>
            </div>
            <Link to="/forgotpassword">Forgot Password?</Link>
          </div>
          <div className="error-container">
            {usernameError && <p className="error-message">{usernameError}</p>}
            {passwordError && <p className="error-message">{passwordError}</p>}
          </div>
          <div className="login_footer">
            <button className="login-button" onClick={onSubmit}>
              {isLoggingIn ? (
                <div className="isLoader">
                  <Loader />
                </div>
              ) : (
                "Login"
              )}
            </button>
            <p className="dont-have-account">
              Don't have an account? <Link to="/signUp">Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;