import React, { useRef, useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { useApp } from "../../AppProvider";
import { Draggable } from "react-beautiful-dnd";
import ProfileImg from "./HomeAssets/Patient Image 2.png";
import EditBookedAppointment from '../../Components/Appointment/EditBookedAppointment';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const Card = ({ task, index }) => {
  const editIconRef = useRef();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { setSelectedPatient, cardDropDownCondition, setSelectedAppointment } = useApp();

  // const navigate = useNavigate();


  const handleClick = (event) => {
    if (task && task.patientId) {
      setSelectedPatient(task.patientId);
      console.log(task)
      setSelectedAppointment(task.id);
      // navigate('/DashboardDashboardNavigation/history');
    }
  };

  function getEventColor(eventType) {
    switch (eventType) {
      case "New":
        return "new-bg";
      case "Regular":
        return "regular-bg";
      case "Critical":
        return "critical-bg";
      case "Published":
        return "published-bg";
      default:
        return "";
    }
  }

  function getTextColor(eventType) {
    switch (eventType) {
      case "New":
        return "new";
      case "Regular":
        return "regular";
      case "Critical":
        return "critical";
      case "Published":
        return "published";
      default:
        return "";
    }
  }

  const filterCards = () => {
    if (cardDropDownCondition === "") {
      return true;
    } else {
      return task.tag === cardDropDownCondition;
    }
  };

  const handleEditAppointment = (event) => {
    event.stopPropagation(); // Prevents click event from bubbling up to parent
    event.preventDefault();
    setEditModalOpen(true);
  };

  return (
    <>
      <Draggable draggableId={task.id} index={index}>
        {(provided, snapshot) => (
          <div
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleClick()
            }}
            style={{ pointerEvents: 'auto' }}>
            {filterCards() && (
              <Link
                to="/DashboardDashboardNavigation/history"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="card-drag"
                style={{
                  userSelect: "none",
                  textDecoration: "none",
                  margin: "0 0 8px 0",
                  background: snapshot.isDragging ? "lightgreen" : "white",
                  ...provided.draggableProps.style
                }}
              >
                <div className="card-main_box">
                  <div className="edit-icon-container"
                    onClick={(event) => { handleEditAppointment(event); }}
                    ref={editIconRef}>
                    <ModeEditOutlineOutlinedIcon className="edit-icons" />
                  </div>
                  <div className="card-section-1">
                    <img src={ProfileImg} alt="img" />
                    <p>{task.content}</p>
                  </div>
                  <div className="card-section-2">
                    <div className={`button-status ${getEventColor(task.tag)} ${getTextColor(task.tag)}`}>
                      {task.tag}
                    </div>
                    <span>{task.time}</span>
                  </div>
                </div>
              </Link>
            )}
          </div>
        )}
      </Draggable>
      <div className="container-appointment_select-slotPage">
        {editModalOpen && (
          <EditBookedAppointment setEditModalOpen={setEditModalOpen} task={task}/>
        )}
      </div>
    </>
  );
};

export default Card;
