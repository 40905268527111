import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../../AuthProvider";
import CloseIcon from "@mui/icons-material/Close";
import "./AddDoctorPopup.css";

const AddDoctorPopup = ({ open, setAddDoctorPopup, onSubmit }) => {
  const popupRef = useRef(null);
  const { accessToken } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [searchDoctorsData, setSearchDoctorsData] = useState([]);

  const fetchSearchDoctorsAPI = async (searchQuery) => {
    if (!open) return [];
    const apiUrl = `https://dev3.backend.cohesivehealth.in/search/doctors/?searchQuery=${searchQuery}`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Fetch error: ${response.status} - ${errorMessage}`);
      }
      const data = await response.json();
      setSearchDoctorsData(data);
      console.log("API Response Data:", data);
    } catch (error) {
      console.error("Error fetching search doctors data:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchSearchDoctorsAPI(searchQuery);
    }
  }, [open, searchQuery]);

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    console.log(searchQuery)
    setSearchQuery(searchQuery);
  };

  const handleSelectDoctor = (doctor) => {
    setSelectedDoctor(doctor);
    setSearchQuery(
      `${doctor.name.firstName} ${(doctor.name.middleName || "")} ${doctor.name.lastName}`
    );
    setSearchDoctorsData([]);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (selectedDoctor) {
      onSubmit(selectedDoctor);
      setAddDoctorPopup(false);
    } else {
      alert("Please select a doctor from the search results.");
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setAddDoctorPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="overlay_page-search-doctor">
      <div className="appointmentCalendar" ref={popupRef}>
        <div className="cross-icon_container" onClick={() => setAddDoctorPopup(false)}>
          <CloseIcon className="cross_icon-image" />
        </div>
        <div className="appointmentCalendar-content">
          <h2>Search Doctor</h2>
          <div className="appointmentCalendar-content-section">
            <p>Enter Doctor's Name or Specialization</p>
            <input
              type="text"
              placeholder="Search Doctor Name/Specialization"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div>
            {searchDoctorsData.length > 0 ? (
              searchDoctorsData.map((doctor, index) => (
                <div
                  className="search-card-appointment_calendar"
                  key={index}
                  onClick={() => handleSelectDoctor(doctor)}
                >
                  <div className="search-section-appointment_calendar">
                    <img src={doctor.img} alt={doctor.name.firstName} />
                    <p>{doctor.name.firstName} {doctor.name.lastName}</p>
                    <p>{doctor.specialization}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No results found</p>
            )}
          </div>
          <button className="search-button" onClick={handleFormSubmit}>
            Add
          </button>
          <button className="search-button" onClick={() => setAddDoctorPopup(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDoctorPopup;
