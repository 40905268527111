import React, { useState } from 'react';
import './AppointmentSelectSlotPage.css';
import { useAuth } from "../../AuthProvider";
import { useApp } from "../../AppProvider";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import CloseIcon from '@mui/icons-material/Close';

const tagOptions = [
  { value: 'New', label: 'New' },
  { value: 'Critical', label: 'Critical' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Published', label: 'Published' }
];

const appNameOptions = [
  { value: 'New', label: 'New' },
  { value: 'Regular Checkup', label: 'Regular Checkup' },
  { value: 'Follow Up', label: 'Follow Up' }
];

const AppointmentSelectSlotPage = ({ patient, setIsAppointmentSelectDatePopupOpen, setIsModalOpenSearchAppointment }) => {
  const { accessToken, user } = useAuth();
  const { selectedClinic, fetchClinicData } = useApp();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('09:00');
  const [timeSlotsApi, setTtimeSlotsApi] = useState([]);
  const [selectedNameOption, setSelectedNameOption] = useState(appNameOptions[1]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [loadingSlot, setLoadingSlot] = useState(false);
  const [popupMessageSlot, setPopupMessageSlot] = useState("");

  const closeModal = () => {
    setIsAppointmentSelectDatePopupOpen(false);
  };

  const fetchEmptySlots = async (date) => {
    const id = selectedClinic._id;
    const dateString = date.toISOString().slice(0, 10);
    const apiUrl = `https://dev3.backend.cohesivehealth.in/clinics/findEmptySlots/${id}?doctorId=${user._id}&date=${dateString}`;
    console.log(apiUrl);
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Fetch error: ${response.status} - ${errorMessage}`);
      }
      const data = await response.json();
      console.log("setSearchAppointmentsData-data", data.emptySlots);
      return data.emptySlots;
    } catch (error) {
      console.error("Error fetching search patients data:", error);
      return [];
    }
  };

  const handleBookAppointment = async () => {
    await setLoadingSlot(true); // Start the loader
    console.log(setLoadingSlot, "loading true")


    try {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      const day = selectedDate.getDate();
      const { hour, minute } = selectedTime.startTime;
      const dateOfAppointment = new Date(Date.UTC(year, month, day, hour, minute));
      const dateOfBooking = new Date();
      const body = {
        clinicId: `${selectedClinic._id}`,
        doctorId: `${user._id}`,
        patientId: `${patient._id}`,
        doctorName: `${user.name.firstName} ${user.name.lastName}`,
        patientName: `${patient.name.firstName} ${patient.name.lastName}`,
        clinicName: `${selectedClinic.name.fullName}`,
        appointmentName: `${selectedNameOption.value}`,
        dateOfAppointment: dateOfAppointment.toISOString(),
        dateOfBooking: dateOfBooking.toISOString(),
        tags: selectedTag ? selectedTag.map(tag => tag.value) : []
      };
      const apiUrl = `https://dev.gateway.cohesivehealth.in/appointments`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
        body: JSON.stringify(body)
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Fetch error: ${response.status} - ${errorMessage}`);
      }
      const data = await response.json();
      await fetchClinicData(user.clinics.join(','));
      console.log("Appointment Scheduled", data);
      setPopupMessageSlot("Update successful!");
      closeModal();
    } catch (error) {
      console.error("Error Scheduling Appointment:", error);
      setPopupMessageSlot("Error updating data.");
    } finally {
      setLoadingSlot(false);
      setIsModalOpenSearchAppointment(false);
    }
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    const slots = await fetchEmptySlots(date);
    setTtimeSlotsApi(slots);
    console.log(date.toISOString().slice(0, 10));
  };

  return (
    <>
      {(loadingSlot || popupMessageSlot) && (
        <div className="popup-overlay_select-slot-page">
          <div className="popup-content_select-slot-page">
            {loadingSlot && <p>Loading...</p>}
            {popupMessageSlot && (
              <>
                <p>{popupMessageSlot}</p>
                <button onClick={() => setPopupMessageSlot("")}>Close</button>
              </>
            )}
          </div>
        </div>
      )}

      <div className="appointment-popup_container-overlay">
        <div className="appointment-popup_container">
          <div className="edit-appointment_header-close">
            <CloseIcon onClick={closeModal} />
          </div>
          <h2 className='selected-appointment_heading'>Book New Appointment</h2>
          <div className='appointment-popup'>
            <div className='date-section_left'>
              <h3>Select Date</h3>
              <DatePicker
                className='date-picker-container'
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                showYearDropdown
                showMonthDropdown
              />
            </div>
            <div className='time-slot-main'>
              <h3>Select Time Slot</h3>
              <div className="time-slot-container">
                {timeSlotsApi && timeSlotsApi.length > 0 ? (
                  timeSlotsApi.map((time, index) => (
                    <div
                      key={index}
                      className={`time-slot-card ${selectedTime === time ? 'selected' : ''}`}
                      onClick={() => setSelectedTime(time)}
                    >
                      {`${time.startTime.hour}:${time.startTime.minute} - ${time.endTime.hour}:${time.endTime.minute}`}
                    </div>
                  ))
                ) : (
                  <div className="no-time-slots-message">
                    Please select a date to view available slots.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="select-slot_input-selector">
            <Select
              value={selectedTag}
              onChange={(option) => setSelectedTag(option)}
              isMulti
              name="tags"
              options={tagOptions}
              className="slot-booking_basic-multi-select"
              classNamePrefix="select"
            />
            <Select
              value={selectedNameOption}
              onChange={(option) => setSelectedNameOption(option)}
              name="appointmentName"
              options={appNameOptions}
              className="slot-booking_basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className='set-appointment-button-container'>
            <button className='set-appointment-button' onClick={handleBookAppointment}>
              Book Appointment
            </button>
            <button className="close-appointment-button" type="button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentSelectSlotPage;
