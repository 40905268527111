import React from "react";
import "./CalendarSidebar.css";
import { useApp } from "../../../AppProvider";
import img1 from "../img/Patient-1.png";
import { Link } from "react-router-dom";


function CalendarSidebar() {
  const { boardData, setSelectedPatient } = useApp();

  // console.log("boardData", boardData.tasks)

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient.patientId);
  }

  function getEventColor(eventType) {
    switch (eventType) {
      case "New":
        return "new-bg";
      case "Regular":
        return "regular-bg";
      case "Critical":
        return "critical-bg";
      case "Published":
        return "published-bg-non";
      default:
        return "";
    }
  }

  function getTextColor(eventType) {
    switch (eventType) {
      case "New":
        return "new-calendar";
      case "Regular":
        return "regular-calendar";
      case "Critical":
        return "critical-calendar";
      case "Published":
        return "published-calendar";
      default:
        return "";
    }
  }

  return (
    <div className="main-calendar">
      <h1 className="headingh1">Today</h1>
      <div className="main-calendar-section">
        {Object.keys(boardData.tasks).length > 0 ? (
          Object.values(boardData.tasks).map((item, index) => {
            return (
              <Link to="/DashboardDashboardNavigation/history" onClick={() => handleSelectPatient(item)} className="calendar-cards" key={index}>
                <div className="img-h1">
                  <img src={img1} className="img" alt="patimg" />
                  <div className="card-text">{item.content}</div>
                </div>
                <div className="bottom-text">
                  <div className={`bottom-text-h ${getEventColor(item.tag)} ${getTextColor(item.tag)}`}>{item.tag}</div>
                  <div className="bottom-time">{item.time}</div>
                </div>
              </Link>
            )
          })
        ) : (
          <div className="no-data-calebdar-sidebar">Data not available</div>
        )}
      </div>
    </div>

  );
}

export default CalendarSidebar;

