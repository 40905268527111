import React, { useState, useEffect } from "react";
import "./PastAppointment.css";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { useApp } from "./../../../AppProvider";
import { useAuth } from "./../../../AuthProvider";
import Reports from "../../../assets/Reports.png";
import Medical from "../../../assets/Medical.png";

function PastAppointment() {
  const { patientsMHR } = useApp();
  const { user } = useAuth();
  const [activeLink, setActiveLink] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const PastAppointment = patientsMHR.filter(mhr => {
    return (
      mhr.doctorId === user._id && // Filter by doctorId
      mhr.typeOfRecord === "PrescriptionRecord"
    );
  });
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" }
  ];

  useEffect(() => {
    setActiveLink("records");
  }, []);

  const RecordCard = ({ record }) => {
    const { recordId } = useParams();
    const isActive = record.recordId === recordId;

    const logos = {
      PathologyRecord: Reports,
      RadiologyRecord: Medical,
    };
    const logo = logos[record.typeOfRecord] || Reports;

    console.log("past record's", record)

    return (
      <Link
        className={`linkRec records`}
        to={`/DashboardDashboardNavigation/records/${record.recordId}`}
      >
        <div className={`card-one ${isActive ? "active-card" : ""}`}>
          <div className="card-one_header">
            <img src={logo} className="icon-1" alt="reports" />
            <div className={`${isActive ? "jun" : "may-1"}`}>
              {new Date(record.dateOfPublish).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
              })}
            </div>
          </div>
          <div className="type-of_records">{record.typeOfRecord}</div>
          <div className="doctor_notes-text">
            <p className="doctor-text">
              {record.doctorsNote}
            </p>
          </div>
          <div className="diagnosis-main-container">
            {record.diagnosis.map((item, index) =>
              item ? (
                <p key={index} className="capsule">{item}</p>
              ) : null
            )}
          </div>

        </div>
      </Link>
    );
  };

  return (
    <div className="sidebar-container">
      <div className="past-container">
        <div className="past-appointment_heading">
          <h1>Past Records</h1>
        </div>
        <div className="records-dropdown_past">
          <Select
            className="records-dropdown-1"
            options={options}
            value={selectedOption}
            onChange={setSelectedOption}
            placeholder="Select Record"
          />
        </div>
        <div className="card-main_pastAppontment">
          {PastAppointment.map((record, index) => (
            <RecordCard record={record} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PastAppointment;
