import React, { useState, useEffect, useRef } from "react";
import "./DashboardHeader.css";
import { Link } from "react-router-dom";
import AddMedicine from "../DoctorNotes/Modal/AddMedicine/AddMedicine";
import AddtestPopup from "../DoctorNotes/Modal/AddMedicine/AddtestPopup";
import CustomBotton from "../../../Components/CustomButton";

const DashboardHeader = () => {
  const [activeLink, setActiveLink] = useState("");
  const [isMedicineModalOpen, setIsMedicineModalOpen] = useState(false);
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const medicineModalRef = useRef();
  const testModalRef = useRef();

  useEffect(() => {
    setActiveLink("history");
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        medicineModalRef.current &&
        !medicineModalRef.current.contains(event.target)
      ) {
        setIsMedicineModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [medicineModalRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        testModalRef.current &&
        !testModalRef.current.contains(event.target)
      ) {
        setIsTestModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [testModalRef]);

  return (
    <div className="dashboadr-header_container">
      <div className="div-dashboard-header">
        <Link
          className={`link ${activeLink === "history" ? "active" : ""}`}
          to="/DashboardDashboardNavigation"
          onClick={() => setActiveLink("history")}
        >
          <span>History</span>
        </Link>
        <Link
          className={`link ${activeLink === "doctornotes" ? "active" : ""}`}
          to="/DashboardDashboardNavigation/doctornotes"
          onClick={() => setActiveLink("doctornotes")}
        >
          <span>Doctor Notes</span>
        </Link>
        <Link
          className={`link ${activeLink === "records" ? "active" : ""}`}
          to="/DashboardDashboardNavigation/records"
          onClick={() => setActiveLink("records")}
        >
          <span>Records</span>
        </Link>
        {/* Pless Don't uncomment this data vazualization section */}
        {/* <Link
          className={`link ${activeLink === "visualization" ? "active" : ""}`}
          to="/DashboardDashboardNavigation/visualization"
          onClick={() => setActiveLink("visualization")}
        >
          <span>Data Visualization</span>
        </Link> */}
        <div className="button">
          <CustomBotton onClick={() => setIsTestModalOpen(true)}>
            Add Tests
          </CustomBotton>
          <CustomBotton onClick={() => setIsMedicineModalOpen(true)}>
            Add Medicine
          </CustomBotton>
        </div>
        {isMedicineModalOpen && (
            <AddMedicine setIsMedicineModalOpen={setIsMedicineModalOpen} />
        )}
        {isTestModalOpen && (
            <AddtestPopup setIsTestModalOpen={setIsTestModalOpen} />
        )}
      </div>
      <hr style={{ background: "#f1f1f1", height: "1px", width: "100vw" }} />
    </div>
  );
};

export default DashboardHeader;