import React, { useState, useEffect } from "react";
import "./RecordsMain.css";
import { useParams, useNavigate } from "react-router-dom";
import { useApp } from "./../../../../../AppProvider";
import { useAuth } from "./../../../../../AuthProvider";

const PrescriptionRecord = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const [fileUrls, setFileUrls] = useState([]);
  const { mediTableContent, patientsMHR } = useApp();

  let { recordId } = useParams();

  if (recordId === undefined) {
    recordId = patientsMHR[0].recordId;
    navigate(`/DashboardDashboardNavigation/records/${recordId}`);
  }

  const record = patientsMHR.find((item) => item.recordId === recordId);

  useEffect(() => {
    const fetchPdfs = async () => {
      const urls = await Promise.all(
        record.files.map(async (fileId) => {
          const response = await fetch(
            `https://dev3.backend.cohesivehealth.in/uploads/${fileId}`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          );
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        })
      );
      setFileUrls(urls);
    };

    if (record.files && record.files.length > 0) {
      fetchPdfs();
    }
  }, [record]);

  console.log("record", record.medications)

  return (
    <div className="card-container-main">
      <h2 className="card-heading-1">Prescription Record</h2>
      {/* <div className="card-container-records">
        <div className="card-records card-records-one">
      <div className="card-container-records">
        <div className="card-records prescription-card-records-one">
          <h2 className="card-heading-1">Prescription Record</h2>
          <ul className="card-bottom-text">
            {record.medications.map((item, index) => (
              <li className="testName-section" key={index}>
                {item.brand} {item.dosage} {item.duration}:{" "}
                <span className="card-bottom-text-count">{item.quantity}</span>
              </li>
            ))}
          </ul>
        </div>
      </div> */}
      <div className="doctor-notes-column-1">
        <div className="cards-section-pre">
          <div className="cards-container-tag">
            <h2>Symptoms</h2>
            <hr className="horizontal-line" />
            <p className="prescription-para"> {record.symptoms ? record.symptoms : ""} </p>
          </div>
        </div>
        <div className="cards-section-pre">
          <div className="cards-container-tag">
            <h2>Diagnosis</h2>
            <hr className="horizontal-line" />
            <p className="prescription-para">{record.diagnosis ? record.diagnosis : ""}</p>
          </div>
        </div>
      </div>
      <div className="doctor-notes-column-1">
        <div className="cards-section-pre">
          <div className="cards-container-tag">
            <h2>Advice</h2>
            <hr className="horizontal-line" />
            <p className="prescription-para">{record.advice ? record.advice : ""}</p>
          </div>
        </div>
        <div className="cards-section-pre">
          <div className="cards-container-tag">
            <h2>Things to remember</h2>
            <hr className="horizontal-line" />
            <p className="prescription-para">{record.remember ? record.remember : ""}</p>
          </div>
        </div>
      </div>
      <h2 className="cards-heading-medicine">Medicines</h2>
      <div className="medi-table-container-notes">
        <div className="medi-table-content">
          <table className="medi-table">
            <thead>
              <tr className="medi-table-header">
                <th className="medi-header-text">S.No.</th>
                <th className="medi-header-text">Brand Name</th>
                <th className="medi-header-text">Drug Name</th>
                <th className="medi-header-text">Quantity</th>
                <th className="medi-header-text">Duration</th>
                <th className="medi-header-text">Dosage</th>
                <th className="medi-header-text">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {record.medications.length > 0 ? (
                record.medications.map((group, index) => (
                  <tr className="medi-table-body" key={index}>
                    <td className="medi-table-el" style={{ textAlign: "center" }}> {index + 1}</td>
                    <td className="medi-table-el">{group.brand}</td>
                    <td className="medi-table-el">{group.drugsName}</td>
                    <td className="medi-table-el" style={{ textAlign: "center" }}> {group.quantity}</td>
                    <td className="medi-table-el" style={{ textAlign: "center" }}> {group.duration}</td>
                    <td className="medi-table-el" style={{ textAlign: "center" }}>{group.dosage}</td>
                    <td className="medi-table-el"> {group.remarks}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="medi-no-medicine-selected">
                    Medicine Not Alloted
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <h2 className="cards-heading-test">Tests</h2>
      <div className="test-table-container-notes">
        <div className="test-table-content-notes">
          <table className="test-table">
            <thead>
              <tr className="test-thead-lab">
                <th className="test-header-text serial-tests-th">S.No.</th>
                <th className="test-header-text">Lab Name</th>
                <th className="test-header-text">Test</th>
                <th className="test-header-text delete-tests-th">Delete</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div >
  );
};

export default PrescriptionRecord;