import React from "react";
import "./DataVisualization.css";
import Pathology from "./images/Pathology Reports.png";
import Medical from "./images/Medical Images.png";
import Vaccination from "./images/Vaccination.png";
import Prescriptions from "./images/Prescription.png";
import Hospital from "./images/Hospital Records.png";
import Expenses from "./images/Medical Expenses.png";
import Calendar from "./images/Calendar.png";
import DownIcon from "./images/Down.png";

const DataVisualization = () => {
  return (
    <>
      <div className="main-visualization">
        <div className="alls-card">
          <div className="card-visual-clr1">
            <div className="card-visual">
              <div className="card-title">
                <h3>Pathology Reports</h3>
                <div className="img-icon">
                  <div className="imge">
                    <img src={Pathology} alt="" />
                  </div>
                  <div className="num">
                    <p>3</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-visual-clr2">
            <div className="card-visual">
              <div className="card-title">
                <h3>Medical Images</h3>
                <div className="img-icon">
                  <div className="imge">
                    <img src={Medical} alt="" />
                  </div>
                  <div className="num">
                    <p>5</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-visual-clr3">
            <div className="card-visual">
              <div className="card-title">
                <h3>Vaccination</h3>
                <div className="img-icon">
                  <div className="imge">
                    <img src={Vaccination} alt="" />
                  </div>
                  <div className="num">
                    <p>4</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-visual-clr4">
            <div className="card-visual">
              <div className="card-title">
                <h3>Prescriptions</h3>
                <div className="img-icon">
                  <div className="imge">
                    <img src={Prescriptions} alt="" />
                  </div>
                  <div className="num">
                    <p>2</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-visual-clr5">
            <div className="card-visual">
              <div className="card-title">
                <h3>Hospitalization</h3>
                <div className="img-icon">
                  <div className="imge">
                    <img src={Hospital} alt="" />
                  </div>
                  <div className="num">
                    <p>1</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-visual-clr6">
            <div className="card-visual">
              <div className="card-title">
                <h3>Medical Expenses</h3>
                <div className="img-icon">
                  <div className="imge">
                    <img src={Expenses} alt="" />
                  </div>
                  <div className="num">
                    <p>3</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hr"></div>
      {/* bottm details  */}
      <div className="main-schedule">
        <div className="schedule">
          <div className="section-condition">
            <div className="box-text">Select Date Range</div>
            <img className="downbutton" src={Calendar} alt="" />
          </div>

          <div className="section-condition">
            <div className="box-text">Select Record</div>
            <img className="downbutton" src={DownIcon} alt="" />
          </div>

          <div className="section-condition">
            <div className="box-text">Select Parameters</div>
            <img className="downbutton" src={DownIcon} alt="" />
          </div>
          <div className="section-condition">
            <div className="box-text">Select Personal Health Record</div>
            <img className="downbutton" src={DownIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DataVisualization;
