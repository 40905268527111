import React from "react";
import "./Records.css";
import RecordsMain from "./RecordsSidebar/RecordsMain/RecordsMain";
import PrescriptionRecord from "./RecordsSidebar/RecordsMain/PrescriptionRecord"
import RadiologyRecord from "./RecordsSidebar/RecordsMain/RadiologyRecord"
import VaccinationRecord from "./RecordsSidebar/RecordsMain/VaccinationRecord"
import HospitalizationReccord from "./RecordsSidebar/RecordsMain/HospitalizationRecord"
import { useParams, useNavigate } from "react-router-dom";
import { useApp } from "./../../../AppProvider";

const Records = () => {
  const { patientsMHR } = useApp();
  const navigate = useNavigate();
  let { recordId } = useParams();

  if (recordId === undefined) {
    recordId = patientsMHR[0].recordId;
    navigate(`/DashboardDashboardNavigation/records/${recordId}`);
  }

  const record = patientsMHR.find((item) => item.recordId === recordId);

  if (record.typeOfRecord === "PathologyRecord") {
    return <RecordsMain />;
  } 
  else if (record.typeOfRecord === "PrescriptionRecord") {
    return <PrescriptionRecord />;
  }
  else if (record.typeOfRecord === "RadiologyRecord") {
    return <RadiologyRecord />;
  }
  else if (record.typeOfRecord === "VaccinationRecord") {
    return <VaccinationRecord />;
  }
  else if (record.typeOfRecord === "HospitalizationReccord") {
    return <HospitalizationReccord />;
  }
  else {
    return <RecordsMain />;
  }
};

export default Records;
