import React, { useState, useEffect } from "react";
import "./AddSpecialization.css";
import CrossIcon from "../SettingsAssets/cross-svgrepo-com.svg";
import { useAuth } from "../../../AuthProvider";

const handleSectionClick = (e) => {
  e.stopPropagation();
};

const AddSpecialization = ({ open, onClose, addData, data, type, placeholder, fetchDoctorProfile, user }) => {
  const { setUser } = useAuth();
  const [newItem, setNewItem] = useState("");
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    setTempData(data);
  }, [data]);

  if (!open) return null;

  const handleAddSpecialization = () => {
    if (newItem.trim() !== "") {
      const updatedData = [...tempData, newItem];
      setTempData(updatedData);
      setNewItem(""); 
    }
  };

  const handleSave = async () => {
    const updatedProfile = { ...user, specializations: tempData };
    console.log("SAVE PROFILE: ", updatedProfile);
    try {
      await fetchDoctorProfile(user._id, updatedProfile);
      setUser(updatedProfile);
      addData(tempData); // Send updated data back to parent
      console.log("Profile updated, new data: ", updatedProfile);
    } catch (error) {
      console.error("Failed to update profile: ", error);
    } finally {
      onClose();
    }
  };

  const handleCancel = () => {
    setTempData(data);
    setNewItem(""); 
    onClose();
  };

  return (
    <div className="specialization-container">
      <div className="specialization-background" onClick={handleCancel}></div>
      <div className="specialization-content">
        <div className="specialization-section-1" onClick={handleSectionClick}>
          <img
            className="specialization-cross"
            src={CrossIcon}
            alt="icon"
            onClick={handleCancel}
          />
          <div className="specialization-heading">
            {type === "specialization" ? "Specialization" : "Education"}
          </div>
          <div className="specialization-skills-container">
            {tempData.length === 0 ? (
              <div className="specialization-add-skill-text">
                Add {type === "specialization" ? "Specialization" : "Education"}{" "}
                <span>&#43;</span>
              </div>
            ) : (
              <div className="specialization-skills-container">
                {tempData.map((item, index) => (
                  <div key={index} className="specialization-skill-item">
                    {item}
                    <img
                      className="specialization-spec-cross"
                      src={CrossIcon}
                      alt="Cross Icon"
                      onClick={() => {
                        const updatedData = tempData.filter((_, i) => i !== index);
                        setTempData(updatedData);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <input
            className="specialization-input"
            placeholder={placeholder}
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />
          <button className="specialization-add-btn" onClick={handleAddSpecialization}>
            Add Specialization
          </button>
          <div className="specialization-btn-box">
            <button className="specialization-cancel-btn" onClick={handleCancel}>
              Cancel
            </button>
            <button className="specialization-save-btn" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSpecialization;
