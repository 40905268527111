import React, { useState } from "react";
import PlusIcon from "../../../Setting/SettingsAssets/Plus.svg";
import { useParams } from "react-router-dom";
import ManageMedicineCards from "../../ManageMedicineCards";
import MedicineMain from "../MedicineMain/MedicineMain";
import { useNavigate } from "react-router-dom";
import "./MedicineSidebar.css";
import Dropdown from "react-dropdown";
import { useAuth } from "../../../../AuthProvider";
import { useApp } from "../../../../AppProvider";

const MedicineSidebar = ({ medicineData, setMedicineData }) => {
  const navigate = useNavigate();
  const { accessToken, user } = useAuth();
  const { medicationGroup } = useApp();
  const { MediRecordId } = useParams();
  const [groups, setGroups] = useState();
  const [medicineCards, setMedicineCards] = useState();


  const createMedicineNewData = async (groupName) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/medicationGroups`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST", 
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
        body: JSON.stringify({ groupName, doctorId: `${user._id}` }), 
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      return data
      console.log("Updated medicine new data:", data);
    } catch (error) {
      console.error("Error updating medicine data:", error.message);
    }
  };

  const addMedicationGroupToDoctor = async (id) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/doctors/medicationgroups/${user._id}`;
    try {
      const response = await fetch(apiUrl, {
        method: "PUT", // Using PUT method to update data
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
        body: JSON.stringify({ medicationGroups: `${id}` }), // Include groupName and medicines in the request body
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Updated medicine group to Doctor:", data);
      return data

    } catch (error) {
      console.error("Error updating medicine data to Doctor:", error.message);
    }
  };

  const deleteMedicineGroupData = async (id, accessToken) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/medicationGroups/${id}`;
    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }

      console.log("Deleted medicine data:", id);
    } catch (error) {
      console.error("Error deleting medicine data:", error.message);
    }
  };


  const addNewCard = async () => {
    try {
      let newGroup = await createMedicineNewData('New Group');
      try {
        await addMedicationGroupToDoctor(newGroup._id);
        console.log('Successfully added medication group to doctor', newGroup._id);
        await setMedicineData(prevData => [newGroup, ...prevData]);
        navigate(`/setting/managemedicinegroups/${newGroup._id}`);
      } catch (error) {
        await deleteMedicineGroupData(newGroup._id, accessToken);
        console.error("Error in second API call, created group deleted.");
      }
    } catch (error) {
      console.error("Error in first API call.");
    }
  };

  const medicationGroupOptions = medicationGroup.map(group => ({
    label: group.groupName,
    value: group._id
  }));

  return (
    <div className="medi_left_container">
      <div className="medi_content">
        <div className="medi_left_section">
          <div className="medi_left_topbox">
            <div className="medi_left_header">
              <h3 className="medi_heading">Add New Groups</h3>
              <img
                className="medi_plus_icon"
                src={PlusIcon}
                alt="icon"
                onClick={addNewCard}
              />
            </div>
            <Dropdown
              controlClassName="my-dropdown-control_groups-sidebar"
              options={medicationGroupOptions}
              value={groups}
              placeholder="Select Medicine Group"
            />
          </div>
          <div className="medi_left_bottombox">
            <div className="medi_left_listSection">
              <ManageMedicineCards
                medicineData={medicineData}
                MediRecordId={MediRecordId}
              />
              <MedicineMain medicineCards={medicineCards} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicineSidebar;
