import React from 'react'
import './DashboardNavigation.css'
import { Outlet, useLocation } from "react-router-dom";
import DashboardSidebar from './DashboardSidebar/DashboardSidebar'
import DashboardHeader from './DashboardHeader/DashboardHeader';
import RecordsSidebar from './Records/RecordsSidebar/RecordComponents/RecordsSidebar';
import PastAppointment from './DoctorNotes/PastAppointment'
import { useApp } from '../../AppProvider';

const DashboardNavigation = () => {
    const { pastSidebarToggle } = useApp();
    const location = useLocation();
    const path = location.pathname;

    const isHistoryRoute = path.startsWith('/DashboardDashboardNavigation')
    const isDoctorNotesRoute = path.startsWith('/DashboardDashboardNavigation/doctornotes');
    const isRecordRoute = path.startsWith('/DashboardDashboardNavigation/records');
    return (
        <div className="dashboadrNavigation_container">
            {!isDoctorNotesRoute && !isRecordRoute && isHistoryRoute && <DashboardSidebar />}
            {isDoctorNotesRoute ? (pastSidebarToggle ? <PastAppointment /> : <DashboardSidebar />) : null}
            {isRecordRoute ? <RecordsSidebar /> : null}
            <div className="dashboard-section-navigation">
                <DashboardHeader />
                <Outlet />
            </div>
        </div>
    )
}

export default DashboardNavigation;
