// src/components/AddTestPopup.js
import React, { useState, useRef, useEffect, useContext } from "react";
import './AddtestPopup.css';
import DeleteIcon from "../../../../Setting/SettingsAssets/Delete.svg";
import searchIcon from "./Assets/Search.png";

const AddTestPopup = ({ setIsTestModalOpen }) => {
  const menuPortalRef = useRef(null); 
  const [searchLab, setSearchLab] = useState("");
  const [searchTest, setSearchTest] = useState("");
  const [menuPortalTarget, setMenuPortalTarget] = useState(null);

  useEffect(() => {
    setMenuPortalTarget(menuPortalRef.current);
  }, [menuPortalRef.current]);


  const handleSearchLabChange = (event) => {
    setSearchLab(event.target.value);
  };

  const handleSearchTestChange = (event) => {
    setSearchTest(event.target.value);
  };

  return (
    <div className="test-overlay_page">
      <div className="test-main-container">
        <h2 className="test-main-container-heading">Tests</h2>
        <div className="test-input-type-search">
          <div className="test-search-container">
            <img src={searchIcon} className="test-search-icon" alt="search" />
            <input
              className="test-input-type-align"
              type="text"
              placeholder="search labs"
              value={searchLab}
              onChange={handleSearchLabChange}
            />
          </div>
          <div className="test-search-container">
            <img src={searchIcon} className="test-search-icon" alt="search" />
            <input
              className="test-input-type-align"
              type="text"
              placeholder="search tests"
              value={searchTest}
              onChange={handleSearchTestChange}
            />
          </div>
        </div>
        <div className="test-table-wrapper">
          <div className="test-table-section">
            <div className="test-table-container">
              <div className="test-table-content">
                <table className="test-table">
                  <thead>
                    <tr className="test-tableheader">
                      <th className="test-header_text">S.No.</th>
                      <th className="test-header_text">Lab Name</th>
                      <th className="test-header_text">Test</th>
                      <th className="test-header_text delete-test-icon">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {tests.length > 0 ? (
                      tests.map((group) => (
                        <tr key={group.serialNo}>
                          <td className="test-table_el">{group.serialNo}</td>
                          <td className="test-table_el">{group.labName}</td>
                          <td className="test-table_el">{group.test}</td>
                          <td className="test-table_el test-delete-icon">
                            <img
                              className="test-delete_icon"
                              src={DeleteIcon}
                              alt="deleteicon"
                              onClick={() => handleDeleteClick(group.serialNo)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="test-no_tests_selected">
                          No Tests Selected
                        </td>
                      </tr>
                    )} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="test-bottom-button-div">
          <button
            className="test-bottom-button test-bottom-button-right"
            onClick={() => setIsTestModalOpen(false)}
          >
            Cancel
          </button>
          <button className="test-bottom-button test-bottom-button-left">
            Add Test
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTestPopup;
