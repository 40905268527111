import React, { useState, useEffect } from "react";
import "./AddEducation.css";
import CrossIcon from "../SettingsAssets/cross-svgrepo-com.svg";

const handleSectionClick = (e) => {
  e.stopPropagation();
};

const AddEducation = ({ open, onClose, addData, data, placeholder, fetchDoctorProfile, user }) => {
  const [newDegree, setNewDegree] = useState("");
  const [newYear, setNewYear] = useState("");
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    setTempData(data);
  }, [data]);

  if (!open) return null;

  const handleAddEducation = () => {
    if (newDegree.trim() !== "" && newYear.trim() !== "") {
      const newEducation = { degree: newDegree, year: newYear };
      const updatedData = [...tempData, newEducation];
      setTempData(updatedData);
      setNewDegree(""); 
      setNewYear("");
    }
  };

  const handleSave = async () => {
    const updatedProfile = { ...user, education: tempData };
    console.log("SAVE PROFILE: ", updatedProfile);
    try {
      await fetchDoctorProfile(user._id, updatedProfile);
      addData(tempData); // Send updated data back to parent
      console.log("Profile updated, new data: ", tempData);
    } catch (error) {
      console.error("Error updating profile: ", error);
    } finally {
      onClose();
    }
  };

  const handleCancel = () => {
    setTempData(data);
    setNewDegree(""); 
    setNewYear("");
    onClose();
  };

  return (
    <div className="education-container">
      <div className="education-background" onClick={handleCancel}></div>
      <div className="education-content">
        <div className="education-section-1" onClick={handleSectionClick}>
          <img
            className="education-cross"
            src={CrossIcon}
            alt="icon"
            onClick={handleCancel}
          />
          <div className="education-heading">Education</div>
          <div className="education-skills-container">
            {tempData.length === 0 ? (
              <div className="education-add-skill-text">
                Add Education <span>&#43;</span>
              </div>
            ) : (
              <div className="education-skills-container">
                {tempData.map((item, index) => (
                  <div key={index} className="education-skill-item">
                    <div>{item.degree} ({new Date(item.year).getFullYear()})</div>
                    <img
                      className="education-spec-cross"
                      src={CrossIcon}
                      alt="Cross Icon"
                      onClick={() => {
                        const updatedData = tempData.filter((_, i) => i !== index);
                        setTempData(updatedData);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="input_container-section">
            <input
              className="education-input"
              placeholder="Degree"
              value={newDegree}
              onChange={(e) => setNewDegree(e.target.value)}
            />
            <input
              type="date"
              className="education-input-date"
              placeholder="Year"
              value={newYear}
              onChange={(e) => setNewYear(e.target.value)}
            />
          </div>
          <button className="education-add-btn" onClick={handleAddEducation}>
              Add Education
            </button>
          <div className="education-btn-box">
            <button className="education-cancel-btn" onClick={handleCancel}>
              Cancel
            </button>
            <button className="education-save-btn" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEducation;
