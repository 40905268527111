import React, { useState } from 'react';
import './Header.css';
import PROFILE from '../../assets/DoctorProfile.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProfilePopup from '../../Screens/Home/Popup/ProfilePopup/ProfilePopup';
import { useAuth } from './../../AuthProvider';

const Header = () => {
    const { user } = useAuth();
    const [openModalProfilePopup, setOpenModalProfilePopup] = useState(false);

    return (
        <div className='header_container'>
            <div className="profile-section" onClick={() => setOpenModalProfilePopup(true)}>
                <div className="drImage-profile">
                    <img src={PROFILE} alt="" />
                </div>
                <div className='dr-title'>
                    <h5>Dr. {user.name.firstName + " " + user.name.middleName + " " + user.name.lastName}</h5>
                    <div className='profile-spacialization_tag'>
                        <marquee scrollamount="1">{user.specilizations.join(", ")}</marquee>
                    </div>
                </div>
                <ExpandMoreIcon className='downArrowIcon' />
                {openModalProfilePopup && <ProfilePopup onClose={() => setOpenModalProfilePopup(false)} />}
            </div>
        </div>
    );
}

export default Header;
