import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import './Clinics.css';
import LogoWithName from "./image/Logo-with-Name.svg";
import Loader from "../../Components/GlobalLoader/Loader";

const FullScreenLoader = () => (
  <div className="clinic-signup-fullscreen-loader">
    <Loader />
  </div>
);

const specializationsOptions = [
  { value: 'Cardio', label: 'Cardio' },
  { value: 'Eye', label: 'Eye' },
  { value: 'Pediatric', label: 'Pediatric' },
  { value: 'Dental', label: 'Dental' },
  { value: 'Orthopedic', label: 'Orthopedic' }
];

const Clinics = () => {
  const navigate = useNavigate();
  const [isAdding, setIsAdding] = useState(false);
  const [clinicName, setClinicName] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [mapsLink, setMapsLink] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [about, setAbout] = useState("");
  const [specializations, setSpecializations] = useState([]);
  const [logo, setLogo] = useState(null);
  const [timeSlots, setTimeSlots] = useState([{ start: "", end: "", days: [] }]);
  const [contactNumber, setContactNumber] = useState("");
  const [location, setLocation] = useState({ lat: 10, lng: 10 });
  const [successMessage, setSuccessMessage] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [availableClinics, setAvailableClinics] = useState([
    { value: 'Clinic 1', label: 'Clinic 1', id: 'ID1', address: 'Address 1' },
    { value: 'Clinic 2', label: 'Clinic 2', id: 'ID2', address: 'Address 2' },
    { value: 'Clinic 3', label: 'Clinic 3', id: 'ID3', address: 'Address 3' },
  ]);
  const cityOptions = [
    { value: 'City 1', label: 'City 1' },
    { value: 'City 2', label: 'City 2' },
    { value: 'City 3', label: 'City 3' },
  ];

  const stateOptions = [
    { value: 'State 1', label: 'State 1' },
    { value: 'State 2', label: 'State 2' },
    { value: 'State 3', label: 'State 3' },
  ];

  const countryOptions = [
    { value: 'Country 1', label: 'Country 1' },
    { value: 'Country 2', label: 'Country 2' },
    { value: 'Country 3', label: 'Country 3' },
  ];
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [landmark, setLandmark] = useState("");

  const handleAddClinic = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTimeout(() => {
      console.log("Clinic added:", {
        clinicName,
        regNumber,
        clinicAddress,
        mapsLink,
        googleLink,
        about,
        specializations,
        logo,
        timeSlots,
        contactNumber,
        location,
        pincode,
        city,
        state,
        country,
        landmark,
      });
      setIsAdding(false);
      setSuccessMessage(true);
      setCountdown(5);
      setIsSubmitting(false);
    }, 2000);
  };

  const handleSearchClinic = (e) => {
    e.preventDefault();
    console.log("Searching for clinics:", searchQuery);
    setSearchResults(availableClinics.filter(clinic =>
      clinic.label.toLowerCase().includes(searchQuery.toLowerCase())
    ));
  };

  const handleTimeSlotChange = (index, field, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][field] = value;
    setTimeSlots(newTimeSlots);
  };

  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { start: "", end: "", days: [] }]);
  };

  const removeTimeSlot = (index) => {
    const newTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(newTimeSlots);
  };

  useEffect(() => {
    if (successMessage && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      navigate("/login");
    }
  }, [successMessage, countdown, navigate]);

  return (
    <div className="clinic-signup-container">
      {isSubmitting && <FullScreenLoader />}
      <div className={`clinic-signup-login_container ${isSubmitting ? 'clinic-signup-blurred' : ''}`}>
        <div className="clinic-signup-right_container-section">
          {!isAdding && !successMessage ? (
            <div>
              <h1 className="clinic-signup-hello-agin_sign">Clinic Management</h1>
              <div className="clinic-signup-input-section_sign">
                <input
                  type="text"
                  placeholder="Search clinics..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="clinic-signup-input-section"
                />
                <button
                  className="clinic-signup-search-button"
                  onClick={handleSearchClinic}
                >
                  Search Clinic
                </button>
                <div className="clinic-search-results">
                  {searchResults.length > 0 ? (
                    searchResults.map((clinic, index) => (
                      <div key={index} className="clinic-search-result-item">
                        <p><strong>{clinic.label}</strong></p>
                        <p>ID: {clinic.id}</p>
                        <p>Address: {clinic.address}</p>
                      </div>
                    ))
                  ) : (
                    <div className="clinic-search-no-result">No clinics found</div>
                  )}
                </div>
                <button
                  className="clinic-signup-add-button"
                  onClick={() => setIsAdding(true)}
                >
                  Add New Clinic
                </button>
              </div>
              <p className="signup-dont-have-account">
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
          ) : isAdding ? (
            <form className="clinic-signup-input-section" onSubmit={handleAddClinic}>
              <h1 className="clinic-signup-hello-agin_sign">Add Clinic</h1>
              <input
                type="text"
                placeholder="Clinic Name"
                value={clinicName}
                onChange={(e) => setClinicName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Registration Number"
                value={regNumber}
                onChange={(e) => setRegNumber(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Contact Number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
              <Select
                options={cityOptions}
                placeholder="Select City"
                value={city}
                onChange={(selectedCity) => setCity(selectedCity)}
              />
              <Select
                options={stateOptions}
                placeholder="Select State"
                value={state}
                onChange={(selectedState) => setState(selectedState)}
              />
              <Select
                options={countryOptions}
                placeholder="Select Country"
                value={country}
                onChange={(selectedCountry) => setCountry(selectedCountry)}
              />
              <input
                type="text"
                placeholder="Complete Address with Landmark"
                value={clinicAddress}
                onChange={(e) => setMapsLink(e.target.value)}
                required
              />
              <Select
                options={specializationsOptions}
                isMulti
                placeholder="Select Specializations"
                value={specializations}
                onChange={(selectedOptions) => setSpecializations(selectedOptions)}
                className="clinic-signup-multiselect"
              />
              <textarea
                placeholder="About"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                required
              />
              <h3>Select Time Slots</h3>
              {timeSlots.map((slot, index) => (
                <div key={index}>
                  <h6>Slot {index + 1}</h6>
                  <div className="time-slot">
                    <input
                      type="time"
                      value={slot.start}
                      onChange={(e) => handleTimeSlotChange(index, 'start', e.target.value)}
                      required
                    />
                    <input
                      type="time"
                      value={slot.end}
                      onChange={(e) => handleTimeSlotChange(index, 'end', e.target.value)}
                      required
                    />
                  </div>
                  <Select
                    options={[
                      { value: 'Monday', label: 'Monday' },
                      { value: 'Tuesday', label: 'Tuesday' },
                      { value: 'Wednesday', label: 'Wednesday' },
                      { value: 'Thursday', label: 'Thursday' },
                      { value: 'Friday', label: 'Friday' },
                      { value: 'Saturday', label: 'Saturday' },
                      { value: 'Sunday', label: 'Sunday' },
                    ]}
                    isMulti
                    placeholder="Select Days"
                    value={slot.days}
                    onChange={(selectedDays) => handleTimeSlotChange(index, 'days', selectedDays)}
                    className="clinic-signup-multiselect"
                  />
                  {index > 0 && (
                    <button
                      type="button"
                      className="clinic-signup-remove-time-slot-button"
                      onClick={() => removeTimeSlot(index)}
                    >
                      Remove Time Slot
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="clinic-signup-add-time-slot-button"
                onClick={addTimeSlot}
              >
                Add Time Slot
              </button>
              <input
                type="text"
                placeholder="Maps Link"
                value={mapsLink}
                onChange={(e) => setMapsLink(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Google Link"
                value={googleLink}
                onChange={(e) => setGoogleLink(e.target.value)}
                required
              />
              <input
                type="file"
                onChange={(e) => setLogo(e.target.files[0])}
                className="clinic-signup-logo-upload"
              />

              <button type="submit" className="clinic-signup-search-button">
                {isSubmitting ? "Adding..." : "Add Clinic"}
              </button>
              <button
                type="button"
                className="clinic-signup-cancel-button"
                onClick={() => setIsAdding(false)}
              >
                Cancel
              </button>
              <p className="signup-dont-have-account">
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </form>
          ) : (
            <div className="clinic-signup-success-message">
              <h1>Clinic added successfully!</h1>
              <p>Redirecting to login page in {countdown} seconds...</p>
            </div>
          )}
        </div>
        <div className="clinic-signup-left_container-section">
          <img src={LogoWithName} alt="Logo" className="clinic-signup-sign_logo" />
        </div>
      </div>
    </div>
  );
};

export default Clinics;
