import React from "react";
import "./Loader.css";
import { BallTriangle } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="loader-container">
      <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#FFB703"
        ariaLabel="ball-triangle-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
