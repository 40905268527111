import React, { useState, useEffect } from "react";
import "./RecordsSidebar.css";
import Select from "react-select";
import Reports from "./image/Reports.png";
import Medical from "./image/Medical.png";
import { Link, useParams } from "react-router-dom";
import * as utils from "../../../../../Utils";
import { useApp } from "./../../../../../AppProvider";

function RecordsSidebar() {
  const { patientsMHR } = useApp();

  const options = [
    { value: "PathologyRecord", label: "PathologyRecord" },
    { value: "RadiologyRecord", label: "RadiologyRecord" },
    { value: "VaccinationRecord", label: "VaccinationRecord" },
    { value: "PrescriptionRecord", label: "PrescriptionRecord" },
    { value: "HospitalizationRecord", label: "HospitalizationRecord" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [activeLink, setActiveLink] = useState("");

  const filteredData = selectedOption
    ? patientsMHR.filter(record => {
        const isMatch = record.typeOfRecord === selectedOption.value;
        if (!isMatch) {
          console.log(`Mismatch: ${record.typeOfRecord} !== ${selectedOption.value}`);
        }
        return isMatch;
      })
    : patientsMHR;

  useEffect(() => {
    setActiveLink("records");
  }, []);

  const RecordCard = ({ record }) => {
    const { recordId } = useParams();
    const isActive = record.recordId === recordId;
    const logos = {
      PathologyRecord: Reports,
      RadiologyRecord: Medical,
    };
    const logo = logos[record.typeOfRecord] || Reports;

    return (
      <Link
        className={`linkRec records`}
        to={`/DashboardDashboardNavigation/records/${record.recordId}`}
      >
        <div className={`card-one ${isActive ? "active-card" : ""}`}>
          <div className="flex">
            <div>
              <img src={logo} className="icon-1" alt="reports" />
            </div>
            <div className={`${isActive ? "jun" : "may"}`}>
              <p className="june">{utils.formatDate(record.dateOfPublish)}</p>
            </div>
          </div>
          <div className="blood">
            <h1 className="main-heading">{record.typeOfRecord}</h1>
            <ul className="ul">
              {record.testValues &&
                record.testValues
                  .filter((item) => item.classification === "Abnormal")
                  .slice(0, 3) 
                  .map((item, index) => (
                    <li key={index}>
                      {item.testName}:{" "}
                      <span className="red">{item.value}</span>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <>
      <div className="sidebar-container">
        <div className="main-container">
          <div className="h1">
            <h1>Records</h1>
          </div>
          <div className="records-dropdown">
            <Select
              className="records-dropdown-1"
              options={options}
              value={selectedOption}
              onChange={setSelectedOption}
              placeholder="Select Record"
            />
          </div>
          <div className="card-main">
            {filteredData.length > 0 ? (
              filteredData.map((record, index) => (
                <RecordCard key={index} record={record} />
              ))
            ) : (
              <p>No records found for the selected type.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecordsSidebar;
