import React, { useState } from 'react';
import Dropdown from "react-dropdown";
import searchIcon from './Assets/Search.png';
import { useApp } from '../../../../../AppProvider';
import DeleteIcon from '../../../../Setting/SettingsAssets/Delete.svg';
import './AddMedicine.css';

const AddMedicine = ({ setIsMedicineModalOpen }) => {
  const { searchMedicineData, searchQuery, setSearchQuery, setSearchMedicineData, medicationGroup, mediTableContent,
    setMediTableContent, setAddMedicineSelecteGroupsAdvice, selectedPatientObj } = useApp();
  const [addMedicineOption, setAddMedicineOption] = useState();


  const handleDeleteClick = (serialNo) => {
    const updatedMediTableContent = mediTableContent.filter(
      (item) => item.serialNo !== serialNo
    );
    setMediTableContent(
      updatedMediTableContent.map((item, index) => ({
        ...item,
        serialNo: (index + 1).toString()
      }))
    );
  };

  const handleSearchChangeBrand = (event) => {
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
  };

  const handleResultClick = (item) => {
    setSearchMedicineData([]);
    setSearchQuery(item.sku);
    const newMedicine = {
      serialNo: (mediTableContent.length + 1).toString(),
      skuId: item._id,
      brand: item.sku,
      drugsName: item.subGroup,
      quantity: '1 tablet',
      duration: '',
      dosage: '',
      remarks: ''
    };
    setMediTableContent([...mediTableContent, newMedicine]);
    setSearchQuery('');
  };

  const handleInputChange = (serialNo, columnKey, value) => {
    const updatedContent = mediTableContent.map((item) => {
      if (item.serialNo === serialNo) {
        return { ...item, [columnKey]: value };
      }
      return item;
    });
    setMediTableContent(updatedContent);
  };

  const medicationGroupOptions = medicationGroup.map(group => ({
    label: group.groupName,
    value: group._id
  }));

  // console.log("medicationGroup", medicationGroup)

  const addMedicineSelecteGroupsAdviceSection = (group) => {
    setAddMedicineSelecteGroupsAdvice(group.advice); // Use setAddState to update the state
  };


  const handleDropdownChange = (option) => {
    setAddMedicineOption(option.value);
    const selectedGroup = medicationGroup.find(group => group._id === option.value);
    if (selectedGroup) {
      addMedicineSelecteGroupsAdviceSection(selectedGroup);
      const newTableContent = selectedGroup.medicines.map((item, index) => ({
        serialNo: (index + 1).toString(),
        brand: item.brand,
        drugsName: item.drugsName,
        quantity: item.quantity,
        duration: item.duration,
        dosage: item.dosage,
        remarks: item.remark,
        skuId: item._id
      }));
      setMediTableContent(newTableContent);
    }
  };


  return (
    <div className="medicine-overlay_page">
      <div className="medicine-main-container">
        <h2 className="medicine-main-container-heading">Add Medicines {selectedPatientObj.name.firstName + " " + selectedPatientObj.name.lastName}</h2>
        <div className="medicine-input-type-search">
          <div className="medicine-search-container">
            <img src={searchIcon} className="medicine-search-icon" alt="search" />
            <input
              className="medicine-input-type-align"
              type="text"
              placeholder="search brand"
              value={searchQuery}
              onChange={handleSearchChangeBrand}
            />
            {searchMedicineData.length > 0 && (
              <div className="medicine-search-results">
                {searchMedicineData.map((item, index) => (
                  <div
                    className="medicine-brandsearch"
                    key={index}
                    onClick={() => handleResultClick(item)}
                  >
                    {item.sku}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="medicine-dropdown-container">
            <Dropdown
              controlClassName="my-dropdown-control_groups"
              options={medicationGroupOptions}
              onChange={handleDropdownChange}
              value={addMedicineOption}
              placeholder="Select Medicine Group"
            />
          </div>
        </div>
        <div className="medicine-table-wrapper">
          <div className="medicine-table-section">
            <div className="medicine-medi_table_container_notes">
              <div className="medicine-medi-table-content">
                <table className="medicine-medi_table">
                  <thead>
                    <tr className="medicine-tableheader">
                      <th className="medicine-header_text">S.No.</th>
                      <th className="medicine-header_text">Brand Name</th>
                      <th className="medicine-header_text">Drug Name</th>
                      <th className="medicine-header_text">Quantity</th>
                      <th className="medicine-header_text">Duration</th>
                      <th className="medicine-header_text">Dosage</th>
                      <th className="medicine-header_text">Remarks</th>
                      <th className="medicine-header_text">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mediTableContent.length > 0 ? (
                      mediTableContent.map((group) => (
                        <tr key={group.serialNo}>
                          <td className="medicine-table_el">{group.serialNo}</td>
                          <td className="medicine-table_el">{group.brand}</td>
                          <td className="medicine-table_el">{group.drugsName}</td>
                          <td className="medicine-table_el">
                            <input
                              type="text"
                              placeholder="Enter quantity"
                              value={group.quantity}
                              onChange={(e) => handleInputChange(group.serialNo, 'quantity', e.target.value)}
                              className="medicine-table-input"
                              list={`quantity-options-${group.serialNo}`}
                            />
                            <datalist id={`quantity-options-${group.serialNo}`}>
                              <option value="1 tablet"></option>
                              <option value="2 tablet"></option>
                              <option value="3 tablet"></option>
                              <option value="4 tablet"></option>
                              <option value="5 tablet"></option>
                            </datalist>
                          </td>
                          <td className="medicine-table_el">
                            <input
                              type="text"
                              placeholder="Enter duration"
                              value={group.duration}
                              onChange={(e) => handleInputChange(group.serialNo, 'duration', e.target.value)}
                              className="medicine-table-input"
                              list={`duration-options-${group.serialNo}`}
                            />
                            <datalist id={`duration-options-${group.serialNo}`}>
                              <option value="1 day"></option>
                              <option value="3 days"></option>
                              <option value="1 week"></option>
                              <option value="2 weeks"></option>
                              <option value="1 month"></option>
                            </datalist>
                          </td>
                          <td className="medicine-table_el">
                            <input
                              type="text"
                              placeholder="Enter dosage"
                              value={group.dosage}
                              onChange={(e) => handleInputChange(group.serialNo, 'dosage', e.target.value)}
                              className="medicine-table-input"
                              list={`dosage-options-${group.serialNo}`}
                            />
                            <datalist id={`dosage-options-${group.serialNo}`}>
                              <option value="Once a day"></option>
                              <option value="Twice a day"></option>
                              <option value="Thrice a day"></option>
                              <option value="As needed"></option>
                            </datalist>
                          </td>
                          <td className="medicine-table_el">
                            <input
                              type="text"
                              placeholder="Enter remarks"
                              value={group.remarks}
                              onChange={(e) => handleInputChange(group.serialNo, 'remarks', e.target.value)}
                              className="medicine-table-input"
                            />
                          </td>
                          <td className="medicine-table_el medicine-delete-icon">
                            <img
                              className="medicine-delete_icon_doctor"
                              src={DeleteIcon}
                              alt="deleteicon"
                              onClick={() => handleDeleteClick(group.serialNo)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="medicine-no_medicine_selected">
                          No Medicine Selected
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="medicine-bottom-button-div">
          <button className="medicine-bottom-button-right" onClick={() => setIsMedicineModalOpen(false)}>Cancel</button>
          <button className="medicine-bottom-button-left" onClick={() => setIsMedicineModalOpen(false)}>Add Medicine</button>
        </div>
      </div>
    </div>
  );
};

export default AddMedicine;
