import React, { useState } from 'react';
import "./AddSlot.css";
import Select from 'react-select';
import ClearIcon from '@mui/icons-material/Clear';


const dayOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
]
const AddSlot = ({ setAddSlotPopUp }) => {
    const [timeSlots, setTimeSlots] = useState([{ start: "", end: "", days: [] }]);


    const handleTimeSlotChange = (index, field, value) => {
        const newTimeSlots = [...timeSlots];
        newTimeSlots[index][field] = value;
        setTimeSlots(newTimeSlots);
    };

    const addTimeSlot = () => {
        setTimeSlots([...timeSlots, { start: "", end: "", days: [] }]);
    };

    const removeTimeSlot = (index) => {
        const newTimeSlots = timeSlots.filter((_, i) => i !== index);
        setTimeSlots(newTimeSlots);
    };

    return (
        <div className="slot-overlay-page_container">
            <div className="slot-main_container">
                <div>
                    <div className="close-action" onClick={() => { setAddSlotPopUp(false) }}>
                        <ClearIcon />
                    </div>
                    <h3 className="slot-title">Add Slot</h3>
                    <hr />
                    <div className="time-input_container">
                        {timeSlots.map((slot, index) => (
                            <div key={index}>
                                <span className='slot-title-count'>Slot {index + 1}</span>
                                <div className="time-slot_container">
                                    <input
                                        type="time"
                                        value={slot.start}
                                        onChange={(e) => handleTimeSlotChange(index, 'start', e.target.value)}
                                        required
                                    />
                                    <input
                                        type="time"
                                        value={slot.end}
                                        onChange={(e) => handleTimeSlotChange(index, 'end', e.target.value)}
                                        required
                                    />
                                </div>
                                <Select
                                    options={dayOptions}
                                    isMulti
                                    placeholder="Select Days"
                                    value={slot.days}
                                    onChange={(selectedDays) => handleTimeSlotChange(index, 'days', selectedDays)}
                                />
                                {index > 0 && (
                                    <div
                                        type="button"
                                        className="add-slot-remove-time-slot-button"
                                        onClick={() => removeTimeSlot(index)}
                                    >
                                        Remove Slot
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="add-button-new" onClick={addTimeSlot}>Add New Slot</div>
                </div>
                <div className='button_container-addslot'>
                    <div className="add-button-save">Save</div>
                </div>
            </div>
        </div>
    );
};

export default AddSlot;
