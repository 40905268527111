import React, { useState } from "react";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [step, setStep] = useState(0);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleMobileSubmit = () => {
    setStep(1);
  };

  const handleOTPChange = (event) => {
    setOtp(event.target.value);
  };

  const handleOTPSubmit = () => {
    setStep(2);
  };

  return (
    <div className="forgot-container">
      {step === 0 ? (
        <>
          <h1>Enter Mobile Number</h1>
          <input type="text" placeholder="Enter mobile number" onChange={handleMobileChange} />
          <button onClick={handleMobileSubmit}>Send OTP</button>
        </>
      ) : step === 1 ? (
        <>
          <h1>OTP Verification</h1>
          <input type="text" placeholder="Enter OTP" onChange={handleOTPChange} />
          <button onClick={handleOTPSubmit}>Verify OTP</button>
        </>
      ) : (
        <>
          <h1>Forgot Password</h1>
          <input type="password" placeholder="Enter new password" />
          <input type="password" placeholder="Re-Enter new password" />
          <button>Submit</button>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
