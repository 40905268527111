import React, { useState } from "react";
import "./ChangePasswordPopup.css";
import CrossIcon from "../SettingsAssets/cross-svgrepo-com.svg";
import { useAuth } from "../../../AuthProvider";

const ChangePasswordPopup = ({ open, onClose }) => {
  const { forgotPassword } = useAuth();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [statusPopupOpen, setStatusPopupOpen] = useState(false);

  if (!open) return null;
  const handleSectionClick = (e) => {
    e.stopPropagation();
  };

  const validateInputs = () => {
    const errors = {};

    if (!currentPassword) {
      errors.currentPassword = <span className="forgoterror">Current password is required</span>;
    }

    if (!newPassword) {
      errors.newPassword = <span className="forgoterror">New password is required</span>;
    } else if (newPassword.length < 8) {
      errors.newPassword = <span className="forgoterror">New password must be at least 8 characters long</span>;
    } else if (!/\d/.test(newPassword)) {
      errors.newPassword = <span className="forgoterror">New password must contain at least one number</span>;
    } else if (!/[!@#$%^&*]/.test(newPassword)) {
      errors.newPassword = <span className="forgoterror">New password must contain at least one special character</span>;
    }

    if (newPassword !== confirmPassword) {
      errors.confirmPassword = <span className="forgoterror">New passwords do not match</span>;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChangePassword = async () => {
    try {
      await forgotPassword(currentPassword, newPassword, "Doctor", "admin");
      setStatusMessage("Password successfully updated");
      onClose(); 
    } catch (error) {
      console.log("Password not updated", error);
      setStatusMessage("Failed to update password");
    }
  };

  return (
    <div className="popup_container">
      <div className="popup_background"></div>
      <div className="popup_content">
        <div className="section_1" onClick={handleSectionClick}>
          <img className="popup_cross" src={CrossIcon} alt="icon" onClick={onClose} />
          <div className="password_section">
            <div className="password_box">
              <div className="password_heading">Current Password</div>
              <input
                className="password_input"
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              {validationErrors.currentPassword && (
                <div className="error_message">{validationErrors.currentPassword}</div>
              )}
            </div>
            <div className="password_box">
              <div className="password_heading">New Password</div>
              <input
                className="password_input"
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {validationErrors.newPassword && (
                <div className="error_message">{validationErrors.newPassword}</div>
              )}
            </div>
            <div className="password_box">
              <div className="password_heading">Confirm New Password</div>
              <input
                className="password_input"
                type="password"
                placeholder="Re-enter New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {validationErrors.confirmPassword && (
                <div className="error_message">{validationErrors.confirmPassword}</div>
              )}
            </div>
            {errorMessage && <div className="error_message">{errorMessage}</div>}
          </div>
          <div className="btn_change_password" onClick={handleChangePassword}>Change Password</div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPopup;
