import React, { useState } from "react";
import "./PatientForm.css";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { AiOutlineClose } from 'react-icons/ai'; 
import Alcohol from "../Assets/Alcohol.png";
import Cigarette from "../Assets/Cigarette.png";

const animatedComponents = makeAnimated();

const countryCodeOptions = [
  { value: "+91", label: "🇮🇳 +91" }
];

function PatientForm({ onClose }) {
  const [isOpen, setIsOpen] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    dateOfBirth: "", 
    weight: "",
    patientId: "",
    uhid: "",
    bloodGroup: "",
    email: "",
    phoneNumber: "",
    countryCode: "+1",
    medicalCondition: [],
    allergies: [],
    heredity: [],
    image: null,
  });

  const closeModal = () => {
    onClose();
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "weight") {
      if (/^\d*$/.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        gender: checked ? value : "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (selectedOptions, actionMeta) => {
    setFormData((prevData) => ({
      ...prevData,
      [actionMeta.name]: selectedOptions,
    }));
  };

  const handleCountryCodeChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      countryCode: selectedOption.value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  const handleDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };


  const options = [
    { value: "no", label: "No" },
    { value: "ocasionally", label: "Ocasionally (1-2 times a month)" },
    { value: "frequently", label: "Frequently (1-2 times a week)" },
    { value: "daily", label: "Daily" },
  ];

  const medicalConditionOptions = [
    { value: "diabetes", label: "Diabetes" },
    { value: "hypertension", label: "Hypertension" },
    { value: "asthma", label: "Asthma" },
    { value: "cardiac", label: "Cardiac Issues" },
  ];

  const allergiesOptions = [
    { value: "pollen", label: "Pollen" },
    { value: "dust", label: "Dust" },
    { value: "peanuts", label: "Peanuts" },
  ];

  const heredityOptions = [
    { value: "diabetes", label: "Diabetes" },
    { value: "hypertension", label: "Hypertension" },
    { value: "asthma", label: "Asthma" },
  ];

  if (!isOpen) return null;

  return (
    <div className="patient-form-overlay">
      <div className="patient-form-container">
        <AiOutlineClose className="patient-form-close-icon" onClick={closeModal} /> {/* Cross icon to close the popup */}
        <h1 className="patient-form-title">Patient Info</h1>
        <form onSubmit={handleSubmit}>
          <label className="patient-form-label">Upload Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="patient-form-input patient-form-file-input"
          />

          <label className="patient-form-label">Name</label>
          <div className="patient-form-flex-group">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="patient-form-input patient-form-firstname"
              required
            />
            <input
              type="text"
              name="middleName"
              placeholder="Middle Name"
              value={formData.middleName}
              onChange={handleChange}
              className="patient-form-input patient-form-middlename"
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="patient-form-input patient-form-lastname"
              required
            />
          </div>

          <label className="patient-form-label">Email</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="patient-form-input patient-form-email"
            required
          />
          <label className="patient-form-label">Contact Number</label>
          <div className="patient-form-flex-group">
            <select
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
              className="patient-form-select-country-code"
              required
            > 
              <option value="India +91">India +91</option>
            </select>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="patient-form-input patient-form-phone"
              required
            />
          </div>
          <div className="patient-form-flex-group">
            <div>
              <label className="patient-form-label">Gender</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="patient-form-bloodgroup"
                required
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label className="patient-form-label">Blood Group</label>
              <select
                name="bloodGroup"
                value={formData.bloodGroup}
                onChange={handleChange}
                className="patient-form-bloodgroup"
                required
              >
                <option value="A+">A+</option>
                <option value="B+">B+</option>
                <option value="AB">AB</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </select>
            </div>
          </div>
          <div className="patient-form-flex-group">
             <div>
              <label className="patient-form-label">Date of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleDateChange}
                className="patient-form-bloodgroup"
                required
              />
            </div>
            <div>
              <label className="patient-form-label">UHID</label>
              <input
                type="text"
                name="uhid"
                value={formData.uhid}
                onChange={handleChange}
                className="patient-form-bloodgroup"
                required
              />
            </div>
          </div>
            <div className="patient-form-flex-group">
           
          </div>
          <label className="patient-form-label">Medical Condition</label>
          <Select
            name="medicalCondition"
            value={formData.medicalCondition}
            onChange={handleSelectChange}
            options={medicalConditionOptions}
            isMulti
            isSearchable
            components={animatedComponents}
            required
            className="slect-for-medication"
          />
          <label className="patient-form-label">Allergies</label>
          <Select
            name="allergies"
            value={formData.allergies}
            onChange={handleSelectChange}
            options={allergiesOptions}
            isMulti
            isSearchable
            components={animatedComponents}
            required
          />
          <label className="patient-form-label">Heredity</label>
          <Select
            name="heredity"
            value={formData.heredity}
            onChange={handleSelectChange}
            options={heredityOptions}
            isMulti
            isSearchable
            components={animatedComponents}
            required
          />
          <div className="patient-form-addictive-habits">
            <label className="patient-form-label">Addictive Habits</label>
            <div className="patient-form-habit-select">
              <img src={Alcohol} alt="Alcohol" className="patient-form-habit-icon" />
              <Select
                options={options}
                components={animatedComponents}
                className="patient-form-habit-addictive"
              />
              <img src={Cigarette} alt="Cigarette" className="patient-form-habit-icon-cigarrete" />
              <Select
                options={options}
                components={animatedComponents}
                className="patient-form-habit-addictive"
              />
            </div>
          </div>
          <button type="submit" className="patient-form-button">Add Patient</button>
        </form>
      </div>
    </div>
  );
}

export default PatientForm;
