import React, { useEffect, useState } from "react";
import SearchIcon from "./image/Search.png";
import CrossIcon from "./image/cross-svgrepo-com.svg";
import "./Search.css";
import { useAuth } from "../../../AuthProvider";
import { useApp } from "../../../AppProvider";
import { Link } from "react-router-dom";
import PatientForm from "../../../Components/Appointment/PatientForm";

const Search = ({ open, onClose }) => {
  const { accessToken } = useAuth();
  const { setSelectedPatient } = useApp();
  const [searchPatientsData, setSearchPatientsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPatientFormOpen, setIsPatientFormOpen] = useState(false);

  const fetchSearchPatientsAPI = async (searchQuery) => {
    if (!open) return [];

    const apiUrl = `https://dev3.backend.cohesivehealth.in/search/patients/?searchQuery=${searchQuery}`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`
        }
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Fetch error: ${response.status} - ${errorMessage}`);
      }
      const data = await response.json();
      setSearchPatientsData(data);
      console.log("setSearchPatientsData-data", data);
    } catch (error) {
      console.error("Error fetching search patients data:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchSearchPatientsAPI(searchQuery);
    }
  }, [open, searchQuery]);

  useEffect(() => {
    if (!open) return;

    const handleOutsideClick = (event) => {
      const modal = document.querySelector('.section1');
      if (modal && !modal.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [open, onClose]);

  function getEventColor(eventType) {
    switch (eventType) {
      case "New":
        return "new-bg-searchPop";
      case "Regular":
        return "regular-bg-searchPop";
      case "Critical":
        return "critical-bg-searchPop";
      case "Published":
        return "published-bg-searchPop";
      default:
        return "";
    }
  }

  function getTextColor(eventType) {
    switch (eventType) {
      case "New":
        return "new-searchPop";
      case "Regular":
        return "regular-searchPop";
      case "Critical":
        return "critical-searchPop";
      case "Published":
        return "published-searchPop";
      default:
        return "";
    }
  }

  const handleSearchChange = async (event) => {
    const searchQuery = event.target.value;
    setSearchPatientsData([]);
    setSearchQuery(searchQuery);
  };

  const handleSelectPatient = (patient) => {
    setSearchPatientsData([]);
    setSearchQuery(patient.name.firstName + " " + patient.name.middleName + " " + patient.name.lastName);
    setSelectedPatient(patient._id);
  }

  const handleSectionClick = (e) => {
    e.stopPropagation();
  };

  const handleAddPatientClick = () => {
    setIsPatientFormOpen(true);
  };

  const handleClosePatientForm = () => {
    setIsPatientFormOpen(false);
  };

  return (
    <div className="main-background-overlay">
      <div className="main-background" onClick={handleSectionClick}>
        <div className="cross-icon_container">
          <img className="cross_icon-image" src={CrossIcon} alt="cross_icon" onClick={onClose} />
        </div>
        <div className="searchpop">
          <img src={SearchIcon} alt="filter" />
          <input
            className="searchtext"
            type="text"
            placeholder="Search Patients"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div>
          {searchPatientsData.map((item, index) => (
            <Link to="/DashboardDashboardNavigation/history"
              className="search-card" key={index}
              onClick={() => handleSelectPatient(item)}
            >
              <div className="search-section-1">
                <img src={item.img} alt="" />
                <p>{item.name.firstName + " " + item.name.middleName + " " + item.name.lastName}</p>
              </div>
              <div className="search-btn">
                <div className={`search-tag-button ${getEventColor(item.tag)} ${getTextColor(item.tag)}`}>{item.tag}</div>
              </div>
            </Link>
          ))}
        </div>
        <div className="search-add-patient-button-container">
          <button className="search-add-patient-button" onClick={handleAddPatientClick}>Add Patient</button>
        </div>
      </div>
      {isPatientFormOpen && ( <PatientForm onClose={handleClosePatientForm} /> )}
    </div>
  );
};

export default Search;
