import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../AuthProvider.js";
import { useApp } from "../../AppProvider.js";
import CloseIcon from '@mui/icons-material/Close';
import "./AppointmentSearchPage.css";
import PatientForm from "./PatientForm.js";
import AppointmentSelectSlotPage from "./AppointmentSelectSlotPage.js";

function AppointmentSearchPage({ setIsModalOpenSearchAppointment }) {
  const { accessToken } = useAuth();
  const [searchPatientsData, setSearchPatientsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPatientFormOpen, setIsPatientFormOpen] = useState(false);
  const [isAppointmentSelectDatePopupOpen, setIsAppointmentSelectDatePopupOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const popupRef = useRef(null);

  const fetchSearchPatientsAPI = async (searchQuery) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/search/patients/?searchQuery=${searchQuery}`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Fetch error: ${response.status} - ${errorMessage}`);
      }
      const data = await response.json();
      setSearchPatientsData(data);
      console.log("setSearchAppointmentsData-data", data);
    } catch (error) {
      console.error("Error fetching search patients data:", error);
    }
  };

  const handleSearchClick = () => {
    // setShowPopup(true);
  };

  const openPatientForm = () => {
    setIsPatientFormOpen(true);
  };

  const closePatientForm = () => {
    setIsPatientFormOpen(false);
  };

  useEffect(() => {
    fetchSearchPatientsAPI(searchQuery);
  }, [searchQuery]);

  const handleSearchChange = async (event) => {
    const searchQuery = event.target.value;
    setSearchPatientsData([]);
    setSearchQuery(searchQuery);
  };

  const handleSelectPatient = async (patient) => {
    setSearchPatientsData([]);
    setSearchQuery(patient.name.firstName + " " + patient.name.middleName + " " + patient.name.lastName);
    await setSelectedPatient(patient);
    setIsAppointmentSelectDatePopupOpen(true);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsModalOpenSearchAppointment(false); // Close popup when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handelClose = () => {
    setIsModalOpenSearchAppointment(false);
  };

  return (
    <div className="appointment-search_overlay_page">
      <div className="appointment_Calendar" ref={popupRef}>
        <div className="cross-icon_container" onClick={handelClose}>
          <CloseIcon />
        </div>
        <div className="appointmentCalendar-content">
          <h2>Search Patient</h2>
          <div className="appointmentCalendar-content-section-calender">
            <p>Enter Patient's UHID or Patient Name & Mobile Number</p>
            <input
              type="search"
              id="gsearch"
              name="gsearch"
              placeholder="Search UHID/Patient Name/Mobile Number"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="search-card-appointment_calendar_container">
            {searchPatientsData.map((item, index) => (
              <div
                className="search-card-appointment_calendar"
                key={index}
                onClick={() => handleSelectPatient(item)}
              >
                <div className="search-section-appointment_calendar">
                  <img src={item.img} alt="" />
                  <p>
                    {item.name.firstName +
                      " " +
                      item.name.middleName +
                      " " +
                      item.name.lastName}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <button className="search-button-calender" onClick={handleSearchClick}>
            Search
          </button>
          <button className="search-button-calender" onClick={openPatientForm}>
            Add Patient
          </button>
        </div>

        {isPatientFormOpen && <PatientForm onClose={closePatientForm} />}
        {isAppointmentSelectDatePopupOpen && (
          <AppointmentSelectSlotPage
            patient={selectedPatient}
            setIsModalOpenSearchAppointment={setIsModalOpenSearchAppointment}
            setIsAppointmentSelectDatePopupOpen={setIsAppointmentSelectDatePopupOpen}
          />
        )}
      </div>
    </div>
  );
}

export default AppointmentSearchPage;
