import React, { useState } from "react";
import "./ClinicDetailsCard.css";
import { Link, useLocation, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddSlot from "./AddSlot";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import AddDoctorPopup from "./AddDoctorPopup";
import AddAssistantPopup from "./AddAssistantPopup";

const options = [
  { value: "Online", label: "Online" },
  { value: "Offline", label: "Offline" },
];

const initialTimeSlots = [
  {
    day: "Sunday",
    time: [
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" }
    ]
  },
  {
    day: "Monday",
    time: [
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" }
    ]
  },
  {
    day: "Tuesday",
    time: [
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" }
    ]
  },
  {
    day: "Wednesday",
    time: [
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" }
    ]
  },
  {
    day: "Thursday",
    time: [
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" }
    ]
  },
  {
    day: "Friday",
    time: [
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" }
    ]
  },
  {
    day: "Saturday",
    time: [
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" },
      { start: "09:00 AM", end: "01:00 PM" }
    ]
  }
];

const initialAdditionalImages = [
  "https://images.pexels.com/photos/7108332/pexels-photo-7108332.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://www.stinabooth.com/wp-content/uploads/2017/05/07nmcclinics-studiosb.jpg",
];

const availableDoctors = [
  {
    doctorName: "Dr. Ankit Sharma",
    doctorImage:
      "https://img.freepik.com/premium-photo/medical-concept-indian-beautiful-female-doctor-white-coat-with-stethoscope-waist-up-medical-student-woman-hospital-worker-looking-camera-smiling-studio-blue-background_185696-621.jpg?w=1060",
    doctorSpecialization: "Dentist",
  },
  {
    doctorName: "Dr. Aditya",
    doctorImage:
      "https://img.freepik.com/premium-photo/medical-concept-indian-beautiful-female-doctor-white-coat-with-stethoscope-waist-up-medical-student-woman-hospital-worker-looking-camera-smiling-studio-blue-background_185696-621.jpg?w=1060",
    doctorSpecialization: " Cardiologists",
  },
  {
    doctorName: "Dr. Mohd Saif",
    doctorImage:
      "https://img.freepik.com/premium-photo/medical-concept-indian-beautiful-female-doctor-white-coat-with-stethoscope-waist-up-medical-student-woman-hospital-worker-looking-camera-smiling-studio-blue-background_185696-621.jpg?w=1060",
    doctorSpecialization: "Audiologists",
  },
  {
    doctorName: "Dr. Harish Zia",
    doctorImage:
      "https://img.freepik.com/premium-photo/medical-concept-indian-beautiful-female-doctor-white-coat-with-stethoscope-waist-up-medical-student-woman-hospital-worker-looking-camera-smiling-studio-blue-background_185696-621.jpg?w=1060",
    doctorSpecialization: "Orthopedic Surgeon",
  },
  {
    doctorName: "Dr. Mohammad Saif",
    doctorImage:
      "https://img.freepik.com/premium-photo/medical-concept-indian-beautiful-female-doctor-white-coat-with-stethoscope-waist-up-medical-student-woman-hospital-worker-looking-camera-smiling-studio-blue-background_185696-621.jpg?w=1060",
    doctorSpecialization: "Cardiothoracic Surgeon",
  },
];

const availableAssistants = [
  {
    assistantName: "Assistant 1",
    assistantImage:
      "https://img.freepik.com/free-photo/portrait-smiling-young-man-standing-with-arms-crossed-white-background_171337-1002.jpg?w=1060",
    assistantSpecialization: "Nurse",
  },
  {
    assistantName: "Assistant 2",
    assistantImage:
      "https://img.freepik.com/free-photo/portrait-smiling-young-man-standing-with-arms-crossed-white-background_171337-1002.jpg?w=1060",
    assistantSpecialization: "Lab Technician",
  },
  {
    assistantName: "Assistant 3",
    assistantImage:
      "https://img.freepik.com/free-photo/portrait-smiling-young-man-standing-with-arms-crossed-white-background_171337-1002.jpg?w=1060",
    assistantSpecialization: "Pharmacist",
  },
  {
    assistantName: "Assistant 4",
    assistantImage:
      "https://img.freepik.com/free-photo/portrait-smiling-young-man-standing-with-arms-crossed-white-background_171337-1002.jpg?w=1060",
    assistantSpecialization: "Receptionist",
  },
  {
    assistantName: "Assistant 5",
    assistantImage:
      "https://img.freepik.com/free-photo/portrait-smiling-young-man-standing-with-arms-crossed-white-background_171337-1002.jpg?w=1060",
    assistantSpecialization: "Janitor",
  },
];

const ClinicDetailsCard = () => {
  const location = useLocation();
  const { id } = useParams();
  const clinic = location.state?.clinic;
  const [addSlotPopUp, setAddSlotPopUp] = useState(false);
  const [showMoreImages, setShowMoreImages] = useState(false);
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [additionalImages, setAdditionalImages] = useState(
    initialAdditionalImages
  );
  const [addDoctorPopup, setAddDoctorPopup] = useState(false);
  const [doctors, setDoctors] = useState(availableDoctors);
  const [addAssistantPopup, setAddAssistantPopup] = useState(false);
  const [assistants, setAssistants] = useState(availableAssistants);
  const [timeSlots, setTimeSlots] = useState(initialTimeSlots);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [locationInput, setLocationInput] = useState(
    "Gumasta Nagar, Indore, Madhya Pradesh"
  );

  const removeAvailableSlotCapsule = (availableIndex, slotType) => {
    const updatedTimeSlots = timeSlots.map((slot, index) => {
      if (index === availableIndex) {
        return {
          ...slot,
          [slotType]: { start: "", end: "" },
        };
      }
      return slot;
    });
    setTimeSlots(updatedTimeSlots);
  };

  const openFullScreenImage = (index) => {
    setFullScreenImageIndex(index);
  };

  const closeFullScreenImage = () => {
    setFullScreenImageIndex(null);
  };

  const showNextImage = () => {
    setFullScreenImageIndex(
      (prevIndex) => (prevIndex + 1) % additionalImages.length
    );
  };

  const showPreviousImage = () => {
    setFullScreenImageIndex(
      (prevIndex) => (prevIndex - 1 + additionalImages.length) % additionalImages.length
    );
  };

  const removeImage = (index) => {
    setAdditionalImages(additionalImages.filter((_, i) => i !== index));
  };

  const addImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAdditionalImages([...additionalImages, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddDoctor = (doctor) => {
    setDoctors([...doctors, doctor]);
  };

  const handleRemoveDoctor = (index) => {
    setDoctors(doctors.filter((_, i) => i !== index));
  };

  const handleAddAssistant = (assistant) => {
    setAssistants([...assistants, assistant]);
  };

  const handleRemoveAssistant = (index) => {
    setAssistants(assistants.filter((_, i) => i !== index));
  };

  const toggleEditLocation = () => {
    setIsEditingLocation(!isEditingLocation);
  };

  const handleLocationChange = (event) => {
    setLocationInput(event.target.value);
  };

  return (
    <div className="clinic-details-main-container">
      <div className="doctorClinic-details-card_Container">
        <Link className="doctorClinicDetails-card-BackLink" to="/setting/doctorclinic">
          <ArrowBackIosIcon />
          <div>Go Back</div>
        </Link>
        <div className="doctorClinicDetails-logo-Header">
          {/* <img
            src="https://th.bing.com/th/id/OIP.Na0I7neN1sPncMmJ3WZqsQAAAA?rs=1&pid=ImgDetMain"
            alt="clinicLogo"
          /> */}
          <h2>Your Family Clinic</h2>
        </div>
        <div className="blank-section"></div>
      </div>
      <div className="clinic-details-container">
        <div className="clinic-details-left_section">
          <header className="header">
            <div className="main-image">
              <img
                src="https://www.yashodahealthcare.com/blogs/wp-content/uploads/2023/01/radiology-diagnostic-imaging-at-yashoda-hospital-768x512.webp"
                alt="MainProperty"
              />
            </div>
            <div className="side-images">
              <img
                src="https://www.stinabooth.com/wp-content/uploads/2017/05/07nmcclinics-studiosb.jpg"
                alt="RoomPhoto"
                onClick={() => openFullScreenImage(1)}
              />
              <img
                src="https://images.pexels.com/photos/7108332/pexels-photo-7108332.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="GuestPhoto"
                onClick={() => openFullScreenImage(0)}
              />
            </div>
            <div className="side-images">
              <img
                src="https://www.stinabooth.com/wp-content/uploads/2017/05/07nmcclinics-studiosb.jpg"
                alt="RoomPhoto"
                onClick={() => openFullScreenImage(3)}
              />
              <div className="see-more-image-container">
                <img
                  src="https://images.pexels.com/photos/7108332/pexels-photo-7108332.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="GuestPhoto"
                  onClick={() => openFullScreenImage(2)}
                />
                <button
                  className="see-more-button"
                  onClick={() => setShowMoreImages(true)}
                >
                  See More
                </button>
              </div>
            </div>
          </header>
          <div className="clinic-details-left_section-header-right">
            <div className="clinic-details_about">
              <h3 className="about_Heading">About</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incidi dunt ut labore Ut enim ad minim veniam,
                quis Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incidi dunt ut labore Ut enim ad minim
                veniam, quisLorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incidi dunt ut labore Ut enim ad
                minim veniam, quis{" "}
              </p>
              <div className="clinic-details_location-container">
                <div className="clinic-location-box">
                  <LocationOnIcon className="clinic_location-e" />
                  {isEditingLocation ? (
                    <input
                      type="text"
                      value={locationInput}
                      onChange={handleLocationChange}
                      className="location-input"
                    />
                  ) : (
                    locationInput
                  )}
                </div>
                <div
                  className="clinic-details_edit-on-map"
                  onClick={toggleEditLocation}
                >
                  {isEditingLocation ? "Save Location" : "Edit On Map"}
                </div>
              </div>
            </div>
            <hr className="horizintal-line-clinic-details-card" />
            <div className="clinic-details_slot-section">
              <div className="headingcontainer" onClick={() => { setAddSlotPopUp(true); }} >
                <h3 className="slot_Heading">Available Slot</h3>
                <span className="add-slot">
                  <AccessTimeIcon /> Add Slot
                </span>
              </div>
              {initialTimeSlots.map((slot, dayIndex) => (
                <div className="clinic-details_slot-section-container" key={dayIndex} >
                  <div className="day-column">
                    <span className="day-box">{slot.day}</span>
                  </div>
                  <div className="morning-time-column">
                    {slot.time.map((timeSlot, timeIndex) => (
                      <span className="time-box" key={timeIndex}>
                        {`${timeSlot.start} - ${timeSlot.end}`}
                        <CloseIcon className="available-slot-cross_icon" onClick={() => removeAvailableSlotCapsule(dayIndex, timeIndex)} />
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <hr className="horizintal-line-clinic-details-card" />
      <div className="avilableDoctor_container-main">
        <h3 className="avilable-doctor-heading">Available Doctor</h3>
        <span
          className="avilable-doctor_add-button"
          onClick={() => setAddDoctorPopup(true)}
        >
          <AddIcon /> Add New Doctors
        </span>
      </div>

      <div className="clini-details-doctor_container">
        {doctors.map((item, index) => (
          <div className="clinic-details_doctor-box" key={index}>
            <div className="doctor-image-container">
              <img src={item.doctorImage} alt="drImage" />
            </div>
            <div className="doctor-text">
              <h3 className="doctor-name">{item.doctorName}</h3>
              <p className="doctor-specialization">
                {item.doctorSpecialization}
              </p>
              <Select
                className="Online-Offline_selector"
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
              />
            </div>
            <CloseIcon className="remove-doctor-icon" onClick={() => handleRemoveDoctor(index)} />
          </div>
        ))}
      </div>
      <hr className="horizintal-line-clinic-details-card" />
      <div className="avilableDoctor_container-main">
        <h3 className="avilable-doctor-heading">Assistants</h3>
        <span
          className="avilable-doctor_add-button"
          onClick={() => setAddAssistantPopup(true)}
        >
          <AddIcon /> Add Assistant
        </span>
      </div>

      <div className="clini-details-doctor_container">
        {assistants.map((item, index) => (
          <div className="clinic-details_doctor-box" key={index}>
            <div className="doctor-image-container">
              <img src={item.assistantImage} alt="assistantImage" />
            </div>
            <div className="doctor-text">
              <h3 className="doctor-name">{item.assistantName}</h3>
              <p className="doctor-specialization">
                {item.assistantSpecialization}
              </p>
              <Select
                className="Online-Offline_selector"
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
              />
            </div>
            <CloseIcon className="remove-doctor-icon" onClick={() => handleRemoveAssistant(index)} />
          </div>
        ))}
      </div>

      {addSlotPopUp && <AddSlot setAddSlotPopUp={setAddSlotPopUp} />}
      {showMoreImages && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowMoreImages(false)}>
              &times;
            </span>
            <div className="modal-images">
              {additionalImages.map((image, index) => (
                <div className="image-container" key={index}>
                  <img
                    src={image}
                    alt={`Additional ${index + 1}`}
                    onClick={() => openFullScreenImage(index)}
                  />
                  <CloseIcon
                    className="remove-icon"
                    onClick={() => removeImage(index)}
                  />
                </div>
              ))}
              <div className="image-container add-image-container">
                <input
                  type="file"
                  accept="image/*"
                  onChange={addImage}
                  className="add-image-input"
                />
                <AddIcon className="add-icon" />
              </div>
            </div>
          </div>
        </div>
      )}
      {fullScreenImageIndex !== null && (
        <div className="fullscreen-modal">
          <div className="fullscreen-modal-content">
            <span className="close" onClick={closeFullScreenImage}>
              &times;
            </span>
            <img
              src={additionalImages[fullScreenImageIndex]}
              alt="Full Screen"
            />
            <button className="prev-button" onClick={showPreviousImage}>
              &lt;
            </button>
            <button className="next-button" onClick={showNextImage}>
              &gt;
            </button>
          </div>
        </div>
      )}
      {addDoctorPopup && (
        <AddDoctorPopup setAddDoctorPopup={setAddDoctorPopup} onSubmit={handleAddDoctor} />
      )}
      {addAssistantPopup && (
        <AddAssistantPopup setAddAssistantPopup={setAddAssistantPopup} onSubmit={handleAddAssistant} />
      )}
    </div>
  );
};

export default ClinicDetailsCard;
