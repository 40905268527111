import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import LogoWithName from "./image/Logo-with-Name.svg";
import Loader from "../../Components/GlobalLoader/Loader";
import './SignUp.css';

const FullScreenLoader = () => (
  <div className="signup-fullscreen-loader">
    <Loader />
  </div>
);

const SignUp = () => {
  const otpBoxes = useRef([]);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [countdown, setCountdown] = useState(30);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [doctorType, setDoctorType] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successCountdown, setSuccessCountdown] = useState(5);
  const [showResendButton, setShowResendButton] = useState(false);
  const [medicalRegNumber, setMedicalRegNumber] = useState("");
  const [state, setState] = useState("");
  const [dob, setDob] = useState("");
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (countdown > 0 && !showResendButton) {
      const timer = setInterval(() => setCountdown(countdown - 1), 1000);
      return () => clearInterval(timer);
    } else if (countdown === 0) {
      setShowResendButton(true);
    }
  }, [countdown, showResendButton]);

  useEffect(() => {
    if (step === 4 && successCountdown > 0) {
      const timer = setInterval(() => setSuccessCountdown(successCountdown - 1), 1000);
      return () => clearInterval(timer);
    } else if (successCountdown === 0) {
      navigate("/clinics");
    }
  }, [successCountdown, step, navigate]);

  const sendOtpToMobileNumber = () => {
    const smsAuthKey = "YOUR_SMS_AUTH_KEY"; 
    const smsTemplateId = "YOUR_SMS_TEMPLATE_ID"; 
    const phone = `${countryCode.replace("+", "")}${mobileNumber}`;

    fetch(`https://control.msg91.com/api/v5/otp?template_id=${smsTemplateId}&mobile=${phone}`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "authkey": smsAuthKey
      }
    })
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(error => console.error(`OTP Send Error: ${error.message}`));

    console.log("OTP sent to mobile number:", phone);
    setCountdown(30);
    setShowResendButton(false);
  };

  const verifyOtp = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      setStep(3);
    }, 2000);
  };

  const createAccount = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      setStep(4);
    }, 2000);
  };

  const handleStepOneSubmit = (e) => {
    e.preventDefault();
    if (!/^\d{10}$/.test(mobileNumber)) {
      setError("Please enter a valid 10-digit mobile number.");
      return;
    }
    setError(null);
    sendOtpToMobileNumber();
    setStep(2);
  };

  const handleStepTwoSubmit = (e) => {
    e.preventDefault();
    if (otp.length !== 4 || !/^\d{4}$/.test(otp)) {
      setError("Please enter a valid 4-digit OTP.");
      return;
    }
    setError(null);
    verifyOtp();
  };

  const handleStepThreeSubmit = (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !doctorType || !password || !confirmPassword || !medicalRegNumber || !state || !dob || documents.length === 0) {
      setError("Please fill in all required fields.");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    setError(null);
    createAccount();
  };

  const handleOtpInputChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const updatedOtp = otp.split('');
      updatedOtp[index] = value;
      setOtp(updatedOtp.join(''));

      if (value !== '' && index < 3) {
        otpBoxes.current[index + 1].focus();
      }
      if (value === '' && index > 0) {
        otpBoxes.current[index - 1].focus();
      }
    }
  };

  const resendOtp = () => {
    sendOtpToMobileNumber();
  };

  const handleCountryCodeChange = (text) => {
    const newText = text.startsWith('+') ? text : '+' + text;
    setCountryCode(newText.substring(0, 3));
  };

  const handleDocumentChange = (e) => {
    setDocuments(Array.from(e.target.files));
  };

  return (
    <div className="signup-container">
      {isSubmitting && <FullScreenLoader />}
      <div className={`signup-login_container ${isSubmitting ? 'signup-blurred' : ''}`}>
        <div className="signup-right_container-section">
          {step === 1 && (
            <form onSubmit={handleStepOneSubmit}>
              <h1 className="signup-hello-agin_sign">Doctor's SignUp</h1>
              <div className="signup-input-section_sign">
                <select
                  className="signup-select-container"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value="+91">IN (+91)</option>
                  {/* Add more options as needed */}
                </select>
                <input
                  type="tel"
                  placeholder="Enter mobile number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  maxLength={10}
                />
              </div>
              <div className="signup-error-container">
                {error && <p className="signup-error-message">{error}</p>}
              </div>
              <div className="signup-login_footer">
                <button type="submit" className="signup-login-button">
                  Next
                </button>
              </div>
              <p className="signup-dont-have-account">
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleStepTwoSubmit}>
              <h1 className="signup-hello-agin_sign">OTP Verification</h1>
              <div className="signup-otp-input-container">
                {Array.from({ length: 4 }).map((_, index) => (
                  <input
                    className="signup-otp-input-section"
                    key={index}
                    type="text"
                    maxLength="1"
                    value={otp[index] || ""}
                    onChange={(e) => handleOtpInputChange(index, e.target.value)}
                    ref={(el) => (otpBoxes.current[index] = el)}
                  />
                ))}
              </div>
              <div className="signup-error-container">
                {error && <p className="signup-error-message">{error}</p>}
              </div>
              <div className="signup-login_footer">
                <button type="submit" className="signup-login-button">
                  {isSubmitting ? <Loader /> : "Verify OTP"}
                </button>
                {showResendButton ? (
                  <button type="button" onClick={resendOtp} className="signup-resend-button">
                    Resend OTP
                  </button>
                ) : (
                  <p>Resend OTP in {countdown} seconds</p>
                )}
                <p className="signup-dont-have-account">
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            </form>
          )}
          {step === 3 && (
            <form onSubmit={handleStepThreeSubmit}>
              <h1 className="signup-hello-agin_sign">Complete Your Profile</h1>
              <div className="signup-input-section">
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Middle Name"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Medical Registration Number"
                  value={medicalRegNumber}
                  onChange={(e) => setMedicalRegNumber(e.target.value)}
                />
                <select
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="">Select State</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
                <input className="signup-dob-input"
                  type="date"
                  placeholder="Date of Birth"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
                <select
                  value={doctorType}
                  onChange={(e) => setDoctorType(e.target.value)}
                >
                  <option value="">Select Doctor Type</option>
                  <option value="Neurologist">Neurologist</option>
                  <option value="Cardiologist">Cardiologist</option>
                  <option value="Psychiatrist">Psychiatrist</option>
                  <option value="Oncologist">Oncologist</option>
                  <option value="Pediatrician">Pediatrician</option>
                  <option value="Dermatologist">Dermatologist</option>
                  <option value="Gynecologist">Gynecologist</option>
                </select>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <input className="signup-file-input"
                  type="file"
                  accept=".jpg,.jpeg,.png,.pdf"
                  multiple
                  onChange={handleDocumentChange}
                />
              </div>
              <div className="signup-error-container">
                {error && <p className="signup-error-message">{error}</p>}
              </div>
              <div className="signup-login_footer">
                <button type="submit" className="signup-login-button">
                  {isSubmitting ? <Loader /> : "Proceed"}
                </button>
                <p className="signup-dont-have-account">
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            </form>
          )}
          {step === 4 && (
            <div>
              <h1 className="signup-hello-agin_sign">Doctor's SignUp Successful!</h1>
              <p>Your account has been successfully created.</p>
              <p>Redirecting to clinics page in {successCountdown} seconds...</p>
            </div>
          )}
        </div>
        <div className="signup-left_container-section">
          <h1>Welcome to Cohesive health</h1>
          <img src={LogoWithName} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
