import React, { useState, useEffect } from "react";
import "./RecordsMain.css";
import { useParams, useNavigate } from "react-router-dom";
import { useApp } from "./../../../../../AppProvider";
import { useAuth } from "./../../../../../AuthProvider";

const VaccinationRecord = () => {
  const { patientsMHR } = useApp();
  const { accessToken } = useAuth();
  const [fileUrls, setFileUrls] = useState([]);
  const navigate = useNavigate();
  let { recordId } = useParams();

  if (recordId === undefined) {
    recordId = patientsMHR[0].recordId;
    navigate(`/DashboardDashboardNavigation/records/${recordId}`);
  }

  const record = patientsMHR.find((item) => item.recordId === recordId);

  useEffect(() => {
    const fetchPdfs = async () => {
      const urls = await Promise.all(
        record.files.map(async (fileId) => {
          const response = await fetch(
            `https://dev3.backend.cohesivehealth.in/uploads/${fileId}`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          );
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        })
      );
      setFileUrls(urls);
    };

    if (record.files && record.files.length > 0) {
      fetchPdfs();
    }
  }, [record]);

  return (
    <div className="card-container-main">
      <div className="card-container-records">
        <div className="card-records one">
          <h2 className="card-heading-1">Vaccination Record</h2>
          <ul className="card-bottom-text">
            {record.vaccinations.map((item, index) => (
              <li className="testName-section" key={index}>
                {item.vaccineName}:{" "}
                <span className="card-bottom-text-count">{item.date}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {fileUrls && fileUrls.length > 0 && (
        fileUrls.map((url, index) => (
          <div className="pdf-container" key={index}>
            <iframe
              className="pdf-viewer"
              src={url}
              title={`PDF Viewer ${index}`}
              width="100%"
              height="600px"
            />
          </div>
        ))
      )}
    </div>
  );
};

export default VaccinationRecord;
