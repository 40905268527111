import React, { useEffect, useState } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./Screens/Home/Home";
import Sidebar from "./Components/Sidebar/Sidebar";
import Setting from "./Screens/Setting/Setting";
import DashboardDashboardNavigation from "./Screens/Dashboard/DashboardNavigation";
import Calendar from "./Screens/Calendar/Calendar";
import Chat from "./Screens/Chat/Chat";
import Header from "./Components/Headar/Header";
import History from "./Screens/Dashboard/History/History";
import DoctorNotes from "./Screens/Dashboard/DoctorNotes/DoctorNotes";
import Records from "./Screens/Dashboard/Records/Records";
import DataVisualization from "./Screens/Dashboard/DataVisualization/DataVisualization";
import Login from "./Screens/LogIn/Login";
import MedicineGroups from "./Screens/ManageMedicineGroups/MedicineGroups";
import DoctorProfile from "./Screens/Setting/DoctorProfile";
import { AuthProvider, useAuth } from "./AuthProvider";
import { AppProvider } from "./AppProvider";
import RequireAuth from "./RequireAuth";
import SignUp from "./Screens/LogIn/SignUp";
import ForgotPassword from "./Screens/LogIn/ForgotPassword";
import Loader from "./Components/GlobalLoader/Loader";
import Clinics from "./Screens/LogIn/Clinics";
import DoctorClinic from "./Screens/Setting/DoctorClinic/DoctorClinic";
import ClinicDetailsCard from "./Screens/Setting/DoctorClinic/ClinicDetailsCard";


const Layout = () => {
  const { user, isLoggingIn } = useAuth();
  return (
    <>
      {(user && !isLoggingIn) && <Sidebar />}
      {user && <Header />}
      {user && <Outlet />}
    </>
  );
};

function AppHelper() {
  const [loading, setLoading] = useState(true);
  const { isLoggingIn} = useAuth();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    window.addEventListener("beforeunload", () => {
      setLoading(true);
    });

    return () => clearTimeout(timer);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RequireAuth />, 
      children: [
        {
          path: "/",
          element: <Layout />,
          children: [
            {
              path: "/",
              element: <Home />
            },
            {
              path: "/DashboardDashboardNavigation",
              element: <DashboardDashboardNavigation />,
              children: [
                {
                  path: "history",
                  element: <History />
                },
                {
                  index: true,
                  element: <History />
                },
                {
                  path: "doctornotes",
                  element: <DoctorNotes />
                },
                {
                  path: "records",
                  element: <Outlet />,
                  children: [
                    {
                      path: ":recordId",
                      element: <Records />
                    },
                    {
                      index: true,
                      element: <Records />
                    }
                  ]
                },
                {
                  path: "visualization",
                  element: <DataVisualization />
                }
              ]
            },
            {
              path: "/calendar",
              element: <Calendar />
            },
            {
              path: "/chat",
              element: <Chat />
            },
            {
              path: "/setting",
              element: <Setting />
            },
            {
              path: "setting/doctorprofile",
              element: <DoctorProfile />
            },
            {
              path: "setting/doctorclinic",
              element: <DoctorClinic />,
            },
            {
              path: "setting/doctorclinic/clinicdetailscard",
              element: <Outlet />,
              children: [
                {
                  path: ":clinicdetailscardId",
                  element: <ClinicDetailsCard />
                },
              ]
            },
            {
              path: "setting/managemedicinegroups",
              element: <Outlet />,
              children: [
                {
                  path: ":MediRecordId",
                  element: <MedicineGroups />
                },
                {
                  index: true,
                  path: "",
                  element: <MedicineGroups />
                }
              ]
            },

          ]
        }
      ]
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/signUp",
      element: <SignUp />
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword />
    },
    {
      path: "/clinics",
      element: <Clinics />
    }
  ]);

  if (isLoggingIn) {
    return <Loader />; // or any other loading indicator
  }

  return (
    <AppProvider>
      <div className="App">
        {loading || isLoggingIn ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="container">
          <RouterProvider router={router}></RouterProvider>
        </div>
      )}
      </div>
    </AppProvider>
  );

}

function App() {



  return (
    <AuthProvider>
      {/* <AppProvider> */}
        <AppHelper />
      {/* </AppProvider> */}
    </AuthProvider>
  );
}

export default App;
