import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './Column';
import './Home.css';
import { useApp } from './../../AppProvider';
import { useAuth } from './../../AuthProvider';

const Board = () => {
  const { boardData, setBoardData } = useApp();
  const { accessToken } = useAuth();
  console.log(boardData,'BOARD')
  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = boardData.columns[source.droppableId];
    const finish = boardData.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...boardData,
        columns: {
          ...boardData.columns,
          [newColumn.id]: newColumn,
        },
      };

      setBoardData(newState);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...boardData,
      columns: {
        ...boardData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    console.log("ON CHANGE",destination, source, draggableId )
    // Calling Ststus Change API 
      try {
        const id = draggableId
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${accessToken}`
          },
          body: JSON.stringify({status: destination.droppableId})
        };
        await fetch(`https://dev3.backend.cohesivehealth.in/appointments/${id}`,requestOptions);
        // Optionally, you could update the local state here to reflect the new status
      } catch (error) {
        console.error("Failed to update card status", error);
      }
    setBoardData(newState);
  };

  if (!boardData) {
    return <div className='boardDataNotAvil'>Board data not available yet!</div>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%', marginLeft: 0, marginRight: 0 }}>
        {boardData.columnOrder.map((columnId) => {
          const column = boardData.columns[columnId];
          const tasks = column.taskIds.map((taskId) => boardData.tasks[taskId]);
          return <Column key={column.id} column={column} tasks={tasks} />;
        })}
      </div>
    </DragDropContext>
  );
};

export default Board;
