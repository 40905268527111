import React, { useState, useEffect } from "react";
import "./RecordsMain.css";
import { useParams , useNavigate} from "react-router-dom";
import { useApp } from "./../../../../../AppProvider";
import { useAuth } from "./../../../../../AuthProvider";

const RecordsMain = () => {
  const { patientsMHR } = useApp();
  const [fileUrls, setFileUrls] = useState([]);
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  let { recordId } = useParams();

  if (recordId === undefined) {
    recordId = patientsMHR[0].recordId;
    navigate(`/DashboardDashboardNavigation/records/${recordId}`);
  }
  const record = patientsMHR.find((item) => item.recordId === recordId);
  const isActive = record.recordId === recordId;

  useEffect(() => {
    const fetchPdfs = async () => {
      const urls = await Promise.all(
        record.files.map(async (fileId) => {
          const response = await fetch(
            `https://dev3.backend.cohesivehealth.in/uploads/${fileId}`,
            {
              headers: {
                Authorization: `${accessToken}`
              }
            }
          );
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        })
      );

      setFileUrls(urls);
    };
    if (record.files && record.files.length > 0) {
      fetchPdfs();
    }
  }, [record]);

  return (
    <div className="card-container-main">
      <div class="card-container-records">
        <div class="card-records one">
          <h2 className="card-heading-1">Abnormalities</h2>
          <ul className="card-bottom-text">
            {record.testValues.map((item, index) =>
              item.classification === "Abnormal" ? (
                <li className="testName-section" key={index}>
                  {item.testName}:{" "}
                  <span className="card-bottom-text-count">{item.value}</span>
                </li>
              ) : null
            )}
          </ul>
        </div>
        <div class="card-records two">
          <h2 className="card-heading-2">Warnings</h2>
          <ul className="card-bottom-text">
            {record.testValues.map((item, index) =>
              item.classification === "Warning" ? (
                <li className="testName-section" key={index}>
                  {item.testName}:{" "}
                  <span className="card-bottom-text-count">{item.value}</span>
                </li>
              ) : null
            )}
          </ul>
        </div>
        <div class="card-records three">
          <h2 className="card-heading-3">Normal</h2>
          <ul className="card-bottom-text">
            {record.testValues.map((item, index) =>
              item.classification === "Normal" ? (
                <li className="testName-section" key={index}>
                  {item.testName}:{" "}
                  <span className="card-bottom-text-count">{item.value}</span>
                </li>
              ) : null
            )}
          </ul>
        </div>
      </div>
      <button className="appointment-button">
        <div>
          <span className="text-plus">+</span>
          <span className="text-plus">Appointment</span>
        </div>
      </button>
      {fileUrls && fileUrls.length > 0 ? (
        fileUrls.map((url, index) => (
          <div className="pdf-container">
            <iframe
              key={index}
              className="pdf-viewer"
              src={url}
              title={`PDF Viewer ${index}`}
              width="100%"
              height="600px"
            />
          </div>
        ))
      ) : (
        <p className="no-pdf-text">No PDF files available</p>
      )}
    </div>
  );
};

export default RecordsMain;