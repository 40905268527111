import React, { useEffect, useState } from "react";
import "./DoctorProfile.css";
import StarIcon from "../Setting/SettingsAssets/Star.svg";
import DoctorImg from "../Setting/SettingsAssets/Doctor Profile Pic 2.svg";
import LineIcon from "../Setting/SettingsAssets/Line 25.svg";
import PlusIcon from "./SettingsAssets/Plus.svg";
import AddSpecialization from "./Popup/AddSpecialization";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAuth } from "../../AuthProvider";
import AddEducation from "./Popup/AddEducation";

const DoctorProfile = () => {
  const { user, accessToken, setUser } = useAuth();
  const initialData = {
    ...user,
    specializations: user?.specializations || [],
    education: user?.education || [],
    addresses: user?.addresses || [],
    about: user?.about || "",
    yearsOfExperience: user?.yearsOfExperience || "",
    numberOfPatientsChecked: user?.numberOfPatientsChecked || "",
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(() => {
    const storedData = localStorage.getItem("doctorProfile");
    return storedData ? JSON.parse(storedData) : initialData;
  });
  const [openAddSpecialization, setOpenAddSpecialization] = useState(false);
  const [openAddEducation, setOpenAddEducation] = useState(false);

  const fetchDoctorProfile = async (doctorId, updatedProfile) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/doctors/${doctorId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedProfile),
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setUser(data); // Update user context
      setEditedData(data); // Update local state
      localStorage.setItem("doctorProfile", JSON.stringify(data)); // Save to localStorage
      console.log("Updated Doctor Profile Data:", data);
    } catch (error) {
      console.error("Error updating doctor profile data:", error.message);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchDoctorProfile(user.id, initialData);
    }
  }, [user?.id, accessToken]);

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  const handleSaveButtonClick = async () => {
    setIsEditing(false);
    if (user?._id) {
      await fetchDoctorProfile(user._id, editedData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    localStorage.setItem("doctorProfile", JSON.stringify({ ...editedData, [name]: value }));
  };

  const handleClinicChange = (e, index) => {
    const { value } = e.target;
    const updatedAddresses = [...editedData.addresses];
    updatedAddresses[index].clinic = value;
    setEditedData({
      ...editedData,
      addresses: updatedAddresses,
    });
    localStorage.setItem("doctorProfile", JSON.stringify({ ...editedData, addresses: updatedAddresses }));
  };

  const handleAddressTextChange = (e, index) => {
    const { value } = e.target;
    const updatedAddresses = [...editedData.addresses];
    updatedAddresses[index].address = value;
    setEditedData({
      ...editedData,
      addresses: updatedAddresses,
    });
    localStorage.setItem("doctorProfile", JSON.stringify({ ...editedData, addresses: updatedAddresses }));
  };

  const handleAddSpecialization = (data) => {
    setEditedData({ ...editedData, specializations: data });
    localStorage.setItem("doctorProfile", JSON.stringify({ ...editedData, specializations: data }));
  };

  const handleAddEducation = (data) => {
    setEditedData({ ...editedData, education: data });
    localStorage.setItem("doctorProfile", JSON.stringify({ ...editedData, education: data }));
  };

  return (
    <div className="doctor_profile_container">
      <Link className="backLink" to="/setting">
        <ArrowBackIosIcon />
        <div>Go Back</div>
      </Link>
      <div className="doctor_profile_section">
        <div className="doctor_top_container">
          <img className="doctor_image" src={DoctorImg} alt="doctorimage" />
          <div className="doctor_info_container">
            <h3 className="doctor_heading">
              Dr {user?.name?.firstName} {user?.name?.middleName} {user?.name?.lastName}
            </h3>
            <p className="doctor_role">{editedData.specializations.join(", ")}</p>
            {/* RATING STAR */}
            <div className="doctor_rating_box">
              <div className="star_icon_container">
                {[...Array(5)].map((_, index) => (
                  <img key={index} className="star_icon" src={StarIcon} alt="Star_icon" />
                ))}
              </div>
              <p className="reviews">{user?.reviews?.length}</p>
            </div>
          </div>
        </div>
        {/* Doctor Mid Section */}
        <div className="doctor_mid_section">
          {/* SAVE & EDIT BUTTON */}
          <div className="doc_edit_buttonbox">
            {isEditing ? (
              <button className="doc_save_button" onClick={handleSaveButtonClick}>
                Save
              </button>
            ) : (
              <button className="doc_edit_button" onClick={handleEditButtonClick}>
                Edit
              </button>
            )}
          </div>
          <div className="doctor_left_container">
            <div className="doctor_intro_container">
              {/* INTRODUCTION AREA */}
              <div className="doctor_intro">
                <h3 className="doctor_heading">Introduction</h3>
                <p className="doctor_intro_content">
                  {isEditing ? (
                    <textarea
                      className="doc_intro_textarea"
                      name="about"
                      value={editedData.about || ""}
                      onChange={handleChange}
                    />
                  ) : (
                    editedData.about || ""
                  )}
                </p>
              </div>
              {/* SPECIALIZATION SECTION */}
              <div className="doctor_specialization">
                <img
                  className="doc_plus_icon"
                  src={PlusIcon}
                  alt="Plus Icon"
                  onClick={() => setOpenAddSpecialization(true)}
                />
                <h3 className="doctor_heading">Specialization</h3>
                <div className="doctor_specialization_content">
                  {editedData.specializations.length === 0 ? (
                    <div className="add_specialization_text">Add Specialization</div>
                  ) : (
                    <ul className="doctor_spec_lists">
                      {editedData.specializations.map((spec, index) => (
                        <li key={index} className="doctor_spec_list">
                          {spec}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {/* EDUCATION SECTION */}
              <div className="doctor_education">
                <img
                  className="doc_plus_icon"
                  src={PlusIcon}
                  alt="Plus Icon"
                  onClick={() => setOpenAddEducation(true)}
                />
                <h3 className="doctor_heading">Education</h3>
                <div className="doctor_education_content">
                  <ul className="doctor_edu_lists">
                    {editedData.education?.map((edu, index) => {
                      const year = new Date(edu.year).getFullYear();
                      return (
                        <li key={index} className="doctor_edu_list">
                          {edu.degree} <span>{year}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="doctor_right_container">
            <div className="doctor_experience_box">
              <div className="doctor_exp_content_box">
                <div className="doctor_exp_content">
                  <p className="doctor_text">Years of Experience</p>
                  <p className="doctor_text">Patients Checked</p>
                </div>
                <div className="doctor_exp_content">
                  <p className="doctor_exp_text">
                    {isEditing ? (
                      <input
                        className="doctor_exp_text_input"
                        type="text"
                        name="yearsOfExperience"
                        value={editedData.yearsOfExperience || ""}
                        onChange={handleChange}
                      />
                    ) : (
                      editedData.yearsOfExperience || "N/A"
                    )}
                  </p>
                  <p className="doctor_exp_text">
                    {isEditing ? (
                      <input
                        className="doctor_exp_text_input"
                        type="text"
                        name="numberOfPatientsChecked"
                        value={editedData.numberOfPatientsChecked || ""}
                        onChange={handleChange}
                      />
                    ) : (
                      editedData.numberOfPatientsChecked || "N/A"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="doctor_address_box">
              <h3 className="doctor_heading">Address</h3>
              {editedData?.addresses?.map((address, index) => (
                <div key={index} className="doctor_address_content">
                  <div className="doctor_address_heading">
                    {isEditing ? (
                      <input
                        className="doctor_clinic_input"
                        type="text"
                        value={address.clinic || ""}
                        onChange={(e) => handleClinicChange(e, index)}
                      />
                    ) : (
                      address.clinic || "N/A"
                    )}
                  </div>
                  <div className="doctor_address_text">
                    {isEditing ? (
                      <input
                        className="doctor_address_text_input"
                        type="text"
                        value={address.address || ""}
                        onChange={(e) => handleAddressTextChange(e, index)}
                      />
                    ) : (
                      address.address || "N/A"
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Review Section */}
        <div className="review_container">
          <div className="review_content">
            <h3 className="doctor_heading">Reviews</h3>
            <div className="review_card_container">
              {user?.reviews?.map((review, index) => (
                <div key={index} className="review_card_box">
                  <div className="review_cards">
                    <div className="review_card_text">{review.reviewText}</div>
                    <div className="reviewver_box">
                      <img src={LineIcon} alt="Line Icon" />
                      <div className="reviewver">{review.reviewer}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <AddSpecialization
        fetchDoctorProfile={fetchDoctorProfile}
        open={openAddSpecialization}
        onClose={() => setOpenAddSpecialization(false)}
        addData={handleAddSpecialization}
        data={editedData.specializations}
        type="specialization"
        placeholder="Enter your area of expertise/specialization"
        user={user}
        editedData={editedData}
      />
      <AddEducation
        fetchDoctorProfile={fetchDoctorProfile}
        open={openAddEducation}
        onClose={() => setOpenAddEducation(false)}
        addData={handleAddEducation}
        data={editedData.education}
        type="education"
        placeholder="Enter your education details"
        user={user}
        editedData={editedData}
      />
    </div>
  );
};

export default DoctorProfile;
