import React, { useState } from "react";
import "./Setting.css";
import PlusIcon from "./SettingsAssets/Plus.svg";
import Right from "./SettingsAssets/Arrow.svg";
import { Link } from "react-router-dom";
import ChangePassword from "./Popup/ChangePasswordPopup";
import TwoFactor from "./Popup/TwoFactorAuthenticationPopup";
import AssistantImg from "./SettingsAssets/Patient Image 2.svg";
import CrossIcon from "./SettingsAssets/cross-svgrepo-com.svg";
import { useAuth } from "./../../AuthProvider";

function Setting() {
  const { user } = useAuth();

  const cardDatas = [
    { name: "Tyler", email: "tyler@123.com", img: AssistantImg }
  ];

  const labDatas = [{ name: "Test Lab", location: "India" }];

  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const [openModalTwoFactor, setOpenModalTwoFactor] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  const [assistantData, setAssistantData] = useState([...cardDatas]);
  const [labData, setlabData] = useState([...labDatas]);
  const [labSearch, setLabSearch] = useState("");

  const handleAddAssistant = () => {
    if (
      nameInput.trim() !== "" &&
      emailInput.trim() !== "" &&
      usernameInput.trim() !== ""
    ) {
      const newAssistant = {
        name: nameInput,
        email: emailInput,
        username: usernameInput,
        img: AssistantImg
      };
      setAssistantData((prevData) => [...prevData, newAssistant]);
      setNameInput("");
      setEmailInput("");
      setUsernameInput("");
    }
  };

  const removeAssistant = (index) => {
    setAssistantData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleLab = () => {
    console.log("hhhh", labSearch);
    console.log("Click");
    if (labSearch.trim() !== "") {
      const newLab = {
        name: labSearch,
        location: "India" 
      };
      setlabData((prevData) => [...prevData, newLab]);
      setLabSearch("");
    }
  };

  const removeLab = (index) => {
    setlabData((prevData) => prevData.filter((_, i) => i !== index));
  };


  return (
    <div className="settings_container">
      <div className="main_section">
        {/* PERSONAL INFO SECTION */}
        <div className="personal_info_section">
          <div className="main-button_box">
            <div className="heading">Personal Info</div>
          </div>
          <div className="personal_info_content">
            <div className="labels">
              <div className="personal_info_text">Your Name</div>
              <div className="personal_info_text">Your Email</div>
              <div className="personal_info_text">Phone Number</div>
            </div>
            <div className="labels">
              <div className="personal_info_input">
                Dr. {user.name.firstName} {user.name.lastName}
              </div>
              <div className="personal_info_input">{user.contact.email}</div>
              <div className="personal_info_input">+
                {user.contact.phoneNumber.countryCode &&
                  `${user.contact.phoneNumber.countryCode} ${user.contact.phoneNumber.phoneNumber}`}
              </div>
            </div>
          </div>
        </div>
        {/* <hr className="horizintal-line" /> */}
        {/* ASSISTANT SECTION */}
        {/* <div className="assistant_section">
          <div className="heading">Assistants</div>
          <div className="assistant">
            <div className="assistant_card_container">
              {assistantData.map((assistant, index) => (
                <div key={index} className="assistant_card">
                  <div className="assistant_card_content">
                    <img
                      className="assistant_img"
                      src={AssistantImg}
                      alt="AssistantImage"
                    />
                    <div className="assistant_name">{assistant.name}</div>
                    <img
                      onClick={() => removeAssistant(index)}
                      className="assistant_cross_icon"
                      src={CrossIcon}
                      alt="CrossIcon"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="assistant_2">
              <button className="icon_plus_button" onClick={handleAddAssistant}>
                <img
                  className="icon_plus"
                  src={PlusIcon}
                  alt="Plus Icon"
                />
              </button>
              <div className="el_inputboxes">
                <div className="el_inputbox">
                  <input
                    className="el_input_text"
                    type="text"
                    placeholder="Name"
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                  />
                </div>
                <div className="el_inputbox">
                  <input
                    className="el_input_text"
                    type="text"
                    placeholder="Email"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                  />
                </div>
                <div className="el_inputbox">
                  <input
                    className="el_input_text"
                    type="User Name"
                    placeholder="User Name"
                    value={usernameInput}
                    onChange={(e) => setUsernameInput(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <hr className="horizintal-line" />
        {/* PREFERENCES SECTION*/}
        <div className="preference_section">
          {/* <div className="heading">Preferences</div> */}
          <div className="preference_content">
            {/* <div className="lab">
              <div className="lab_heading">Labs</div>
              <div className="lab_list_container">
                <div className="lab_card_container">
                  {labData.map((lab, index) => (
                    <div key={index} className="lab_card">
                      <div className="lab_card_content">
                        <div className="lab_name">{lab.name}</div>
                        <div>{lab.location}</div>
                        <img
                          onClick={() => removeLab(index)}
                          className="assistant_cross_icon"
                          src={CrossIcon}
                          alt="Cross Icon"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="lab_list">
                  <div className="lab_input_box">
                    <div className="lab_inputtext">Lab Name</div>
                  </div>
                  <div className="lab_input_box">
                    <div className="lab_inputtext">Lab Name</div>
                  </div>
                  <div className="add_new_lab">
                    <img
                      className="lab_add"
                      src={PlusIcon}
                      alt="Plus Icon"
                      onClick={handleLab}
                    />
                    <div className="searchbar_box">
                      <div className="searchbar">
                        <img
                          className="icon_search"
                          src={Search}
                          alt="Search Icon"
                        />
                        <input
                          className="el_input_search"
                          type="text"
                          placeholder="Search Labs"
                          value={labSearch}
                          onChange={(e) => setLabSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="medicines">
              <div className="heading">Medicines</div>
              <Link className="btn_medicines" to="/setting/managemedicinegroups">
                <div className="btn_security_text">Manage Medicines Group</div>
                <img className="icon_right" src={Right} alt="right icon" />
              </Link>
            </div>
          </div>
        </div>

        {/* DOCTOR PROFILE SECTION */}
        <div className="doc_profile_section">
          <div className="heading">Doctor Profile</div>
          <div className="security_content">
            <Link className="btn_doc_profile" to="/setting/doctorprofile">
              <div className="btn_security_text">Doctor Profile</div>
              <img className="icon_right" src={Right} alt="right-icon" />
            </Link>
          </div>
        </div>
        {/* DOCTOR CLINIC */}
        <div className="doc_profile_section">
          <div className="heading">Doctor Clinic</div>
          <div className="security_content">
            <Link className="btn_doc_profile" to="/setting/doctorclinic">
              <div className="btn_security_text">Doctors Clinic</div>
              <img className="icon_right" src={Right} alt="right-icon" />
            </Link>
          </div>
        </div>
        <hr className="horizintal-line" />
        {/* SECURITY SECTION */}
        <div className="security_section">
          <div className="heading">Security</div>
          <div className="security_content">
            <div className="btn_security" onClick={() => setOpenModalChangePassword(true)}>
              <div className="btn_security_text">Change Password</div>
              <img className="icon_right" src={Right} alt="right-icon" />
            </div>
            {/* <div className="btn_security_two" onClick={() => setOpenModalTwoFactor(true)} >
              <div className="btn_security_text">Two Factor Authentication</div>
              <img className="icon_right" src={Right} alt="right icon" />
            </div> */}
          </div>
        </div>
        {openModalChangePassword && (
          <ChangePassword
            open={openModalChangePassword}
            onClose={() => setOpenModalChangePassword(false)}
          />
        )}
        {/* {openModalTwoFactor && (
          <TwoFactor
            open={openModalTwoFactor}
            onClose={() => setOpenModalTwoFactor(false)}
          />
        )} */}
      </div>
    </div>
  );
}

export default Setting;
