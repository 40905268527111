import React, { useEffect, useRef } from "react";
import "./ProfilePopup.css";
import { useAuth } from "./../../../../AuthProvider";
import { useApp } from "./../../../../AppProvider";
import { Link } from "react-router-dom";

function ProfilePopup({ onClose }) {
  const popupRef = useRef(null);
  const { user, logout } = useAuth();
  const { clinics , setSelectedClinic} = useApp();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClick = async(clinicOption) => {
    console.log(clinicOption);
    await setSelectedClinic(clinicOption)
    onClose();
  };
  const handleLogout = (event) => {
    logout();
  };

  return (
    <div className="profile-bg" ref={popupRef}>
      <div className="text-align">
        {clinics.map((item, index) => (
            <label onClick={() => handleClick(item)}>{item.name.fullName}</label>
        ))}
        
        {/* <label onClick={handleClick}>{user.clinics}</label> */}
        <Link
          className="profile_link"
          // onClick={handleClick}
          to="/setting/doctorprofile"
        >
          Profile
        </Link>
        <label onClick={handleLogout}>Logout</label>
      </div>
    </div>
  );
}

export default ProfilePopup;
