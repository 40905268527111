import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.css";
import SearchIcon from "./HomeAssets/Search.png";
import CalendarIcon from "./HomeAssets/Calendar.png";
import AddIcon from "./HomeAssets/VectoAddr.png";
import Board from "./Board";
import Search from "./Popup/Search";
import AppointmentSearchPage from "../../Components/Appointment/AppointmentSearchPage";
import { useApp } from "./../../AppProvider";

export default function Home() {
  const modalRef = useRef();
  const datePickerRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [selectStartDate, setSelectStartDate] = useState(null);
  const { setBoardDate, cardDropDownCondition, setCardDropDownCondition, isModalOpenSearchAppointment, setIsModalOpenSearchAppointment } = useApp();


  const handleSelectDateChange = (date) => {
    const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    setSelectStartDate(date);
    setBoardDate(dateString);
    console.log(dateString);
  };

  const handleSelectDateClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleClearDate = () => {
    setSelectStartDate(null);
    setBoardDate(null);
  };

  const isCurrentDate = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  };

  return (
    <div className="home_container">
      <div className="feed">
        <div className="top-section">
          <div className="search" onClick={() => setOpenModal(true)}>
            <img className="icon" src={SearchIcon} alt="" />
            <div className="searchtext">Search Patients</div>
          </div>
          {openModal && (
            <Search open={openModal} onClose={() => setOpenModal(false)} />
          )}
          <div className="select-date" onClick={handleSelectDateClick}>
            <label>{selectStartDate ? selectStartDate.toLocaleDateString() : "Select Date"}</label>
            <div className="date-picker-container_home">
              <DatePicker
                className="date-picker_home"
                ref={datePickerRef}
                selected={selectStartDate}
                onChange={handleSelectDateChange}
                customInput={
                  <div>
                    <img className="icon" src={CalendarIcon} alt="" />
                  </div>
                }
                renderDayContents={(day, date) => {
                  const today = new Date();
                  const isSameMonthYear = date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
                  const className = isSameMonthYear && isCurrentDate(date) ? 'react-datepicker__day--highlighted' : '';
                  return <div className={className}>{day}</div>;
                }}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled
                }) => (
                  <div>
                    <div className="custom-header">
                      <div className="date-month-year-align">
                        <button className="arrow-calendar" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {"<"}
                        </button>
                        <select className="year-dropdown"
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {Array.from(new Array(10), (_, i) => i + (new Date().getFullYear() - 5)).map(option => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select className="month-dropdown"
                          value={date.getMonth()}
                          onChange={({ target: { value } }) => changeMonth(value)}
                        >
                          {[
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December"
                          ].map((month, index) => (
                            <option key={month} value={index}>
                              {month}
                            </option>
                          ))}
                        </select>
                        <button className="arrow-calendar" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {">"}
                        </button>
                      </div>
                    </div>
                    <button className="clear-date-button" onClick={handleClearDate}>Reset</button>
                  </div>
                )}
                showPopperArrow={false}
              />
            </div>
          </div>
          <Dropdown
            controlClassName="my-dropdown-control"
            options={[
              { value: "", label: "Select Condition" },
              { value: "New", label: "New" },
              { value: "Regular", label: "Regular" },
              { value: "Critical", label: "Critical" },
              { value: "Published", label: "Published" }
            ]}
            onChange={(option) => setCardDropDownCondition(option.value)}
            value={cardDropDownCondition}
          />
          <button className="button-addmedicine" onClick={() => { setIsModalOpenSearchAppointment(true) }}>
            <img alt="logo" src={AddIcon} />
            Book Appointments
          </button>
          {isModalOpenSearchAppointment && (
            <div className="modal" ref={modalRef}>
              <AppointmentSearchPage setIsModalOpenSearchAppointment={setIsModalOpenSearchAppointment} />
            </div>
          )}
        </div>
      </div>
      <div className="bottom-section">
        <h1 className="today_heading">Today</h1>
        <Board />
      </div>
    </div>
  );
}
