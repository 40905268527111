import React from "react";
import "./Sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import { useAuth } from "../../AuthProvider";

const Sidebar = () => {
  const { logout } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="side_bar-container">
      <div className="side_bar-top">
        <div className="linkImg" to="/">
          <img className="imageLogo" src={Logo} alt="Logo" />
        </div>
        <div className="side_items">
          <NavLink exact to="/" className="navLinks-container" activeClassName="active">
            <span className="material-symbols-outlined">home</span>
            <div className="title">Home</div>
          </NavLink>
          <NavLink to="/DashboardDashboardNavigation" className="navLinks-container" activeClassName="active">
            <span className="material-symbols-outlined">grid_view</span>
            <div className="title">Dashboard</div>
          </NavLink>
          <NavLink to="/calendar" className="navLinks-container" activeClassName="active">
            <span className="material-symbols-outlined">calendar_month</span>
            <div className="title">Calendar</div>
          </NavLink>
          <NavLink to="/setting" className="navLinks-container" activeClassName="active">
            <span className="material-symbols-outlined">settings</span>
            <div className="title bottom">Setting</div>
          </NavLink>
        </div>
      </div>

      <div className="side_bar-bottom">
        <div className="link" onClick={handleLogout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 11.5556C8 9.6 9.59964 8 11.5548 8H25.7738V11.5556H11.5548V36.4444H25.7738V40H11.5548C9.59964 40 8 38.4 8 36.4444V11.5556ZM33.1962 22.2222L28.6887 17.7138L31.202 15.2L40 24L31.202 32.8L28.6887 30.2862L33.1962 25.7778H21.4903V22.2222H33.1962Z"
              fill="white"
            />
          </svg>
          <div className="title">Logout</div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
