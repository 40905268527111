import React, { useState } from 'react';
import './DoctorClinic.css';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import AddNewClinicPopup from './AddNewClinicPopup';

const initialClinicData = [
    {
        id: "0001",
        image: 'https://th.bing.com/th/id/R.d4b16e5075153dd5dc2db9c479239359?rik=ci7gLX%2fbowmoXQ&riu=http%3a%2f%2fihg.scene7.com%2fis%2fimage%2fihg%2feven-hotels-eugene-5405616297-4x3&ehk=16ED5J2C4YgE5BpmSJ6%2f4n11Nxewe3ot%2fBrksi8s%2fdQ%3d&risl=&pid=ImgRaw&r=0',
        clinicName: "Healthy Body Center",
        doctorName: "Dr. Heather Moreno",
        clinicLocation: "Tedipuliya adilnagar,Lucknow",
        rating: 4.5,
    },
    {
        id: "0002",
        image: 'https://i.pinimg.com/originals/cb/f6/b4/cbf6b446a7ffff71e73a58256d1af5a9.jpg',
        clinicName: "Healthy Body Center",
        doctorName: "Dr. Heather Moreno",
        clinicLocation: "Tedipuliya adilnagar,Lucknow",
        rating: 4.5,
    },
    {
        id: "0003",
        image: 'https://thearchitecturedesigns.com/wp-content/uploads/2020/06/dental-clinic-5.jpg',
        clinicName: "Healthy Body Center",
        doctorName: "Dr. Heather Moreno",
        clinicLocation: "Tedipuliya adilnagar,Lucknow",
        rating: 4.5,
    },
    {
        id: "0004",
        image: 'https://th.bing.com/th/id/R.840eb7f0d5a4619dae0f4ec6c61dd596?rik=H4JIe5mv%2fCQztQ&riu=http%3a%2f%2fcdn1.ahr-global.com%2fimages%2f2_Cleveland-Clinic2_960x960.jpg&ehk=ViU%2bO6z%2fp5MXoF4g9Oh1Bj%2fAPSyWEatRBYsCV4oiCXA%3d&risl=&pid=ImgRaw&r=0',
        clinicName: "Healthy Body Center",
        doctorName: "Dr. Heather Moreno",
        clinicLocation: "Tedipuliya adilnagar,Lucknow",
        rating: 4.5,
    },
    {
        id: "0005",
        image: 'https://images.squarespace-cdn.com/content/v1/54aec062e4b089533989aa55/1515035709192-WNOUBUCM69LYGVHG77P3/ke17ZwdGBToddI8pDm48kF3ruVomg5sv_aczs-IR-D57gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UQnYRS7D6hHKInM3FM5OjyMAM8HYMWus0nSIj2Y3I8-G6mvBYcl49pnuFyRTfR0mZA/TEH_7529_30_31_32_33_34_35.jpg',
        clinicName: "Healthy Body Center",
        doctorName: "Dr. Heather Moreno",
        clinicLocation: "Tedipuliya adilnagar,Lucknow",
        rating: 4.5,
    },
    {
        id: "0006",
        image: 'https://impeccabuild.com.au/wp-content/uploads/2020/07/Medical-Clinic-Interior-Design-Ideas-Medical-Fitouts-ImpeccaBuild-4-scaled.jpg',
        clinicName: "Healthy Body Center",
        doctorName: "Dr. Heather Moreno",
        clinicLocation: "Tedipuliya adilnagar,Lucknow",
        rating: 4.5,
    },
    {
        id: "0007",
        image: 'https://www.trinityhealth.org/wp-content/uploads/2019/03/clinic.jpg',
        clinicName: "Healthy Body Center",
        doctorName: "Dr. Heather Moreno",
        clinicLocation: "Tedipuliya adilnagar,Lucknow",
        rating: 4.5,
    }
];


const DoctorClinic = () => {
    const [clinicData, setClinicData] = useState(initialClinicData);
    const [addNewClinic, setAddNewClinic] = useState(false);

    const handleAddNewClinic = (newClinic) => {
        setClinicData([...clinicData, { ...newClinic, id: clinicData.length + 1 }]);
        setAddNewClinic(false);
    };

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<StarRateIcon key={i} className="star_icon" />);
        }
        if (halfStar) {
            stars.push(<StarHalfIcon key="half" className="star_icon" />);
        }
        return stars;
    };

    return (
        <div className="doctor_clinic-main_container">
            <div className="doctorClinicContainer">
                <Link className="doctorClinicBackLink" to="/setting">
                    <ArrowBackIosIcon />
                    <div>Go Back</div>
                </Link>
                <div className="doctorClinic-add-new_clinic-button"
                    onClick={() => { setAddNewClinic(true); console.log("okkkk"); }} >
                    <AddIcon className="addIconNewClinic" />
                    <div>Add New Clinic</div>
                </div>
            </div>
            <div className="clinic-card_main-container">
                {clinicData.map((item, index) => (
                    <Link
                        key={index}
                        className="clinic-card-container"
                        to={{ pathname: `/setting/doctorclinic/clinicdetailscard/${item.id}`, state: { clinic: item } }}>
                        <div className="left-box">
                            <img src={item.image} alt={item.clinicName} />
                        </div>
                        <div className="right-box">
                            <div className="clinic-name">{item.clinicName}</div>
                            <div className="doctor-name">{item.doctorName}</div>
                            <div className="location-section">
                                <LocationOnIcon className="location-icon" />
                                <div className="clinic_location">{item.clinicLocation}</div>
                            </div>
                            <div className="rating-section">
                                {renderStars(item.rating)}
                                <div className="rating-count">{item.rating}</div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            {addNewClinic && (
                <AddNewClinicPopup setAddNewClinic={setAddNewClinic} handleAddNewClinic={handleAddNewClinic} />
            )}
        </div>
    );
};

export default DoctorClinic;