export function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = {
        day: 'numeric',
        month: 'short',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
};


